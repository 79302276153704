import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  Button,
  Select,
  // useColorMode,
} from "@chakra-ui/react";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useTranslation } from "react-i18next";
const ApiModal = ({ isOpen, handleClose, audienceId }: any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  // const { colorMode } = useColorMode();
  const [selectedLanguage, setSelectedLanguage] = useState("curl");
  const [code, setCode] = useState(`curl --request POST \\
  --url ${process.env.REACT_APP_BASE_URL}/contact/campaigns?id=${audienceId} \\
  --header 'Content-Type: application/json'  \\
  --header 'x-api-key: <api-key>'  \
  --data '{
  "campaigns_name": "<string>",
  "assistant_id": "<string>",
  "segment_id": "<string>",
  "dialer_id": "<string>",
  "scheduled_at": "<string>",
  "is_automation": true
}'`);

  const handleChange = (event: any) => {
    setSelectedLanguage(event.target.value);
    switch (event.target.value) {
      case "curl":
        setCode(`curl --request POST \\
  --url ${process.env.REACT_APP_BASE_URL}/contact/campaigns?id=${audienceId} \\
  --header 'Content-Type: application/json'  \\
  --header 'x-api-key: <api-key>'  \
  --data '{
  "campaigns_name": "<string>",
  "assistant_id": "<string>",
  "segment_id": "<string>",
  "dialer_id": "<string>",
  "scheduled_at": "<string>",
  "is_automation": true
}'`);
        break;
      case "nodejs":
        setCode(`const options = {
  method: 'POST',
  headers: {'x-api-key': '<api-key>', 'Content-Type': 'application/json'},
  body: '{"first_name":"<string>","last_name":"<string>","email":"<string>","description":"<string>","phone":"<string>"}'
};

fetch('${process.env.REACT_APP_BASE_URL}/contact/campaigns?id=${audienceId}', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));`);
        break;
      case "python":
        setCode(`import requests

url = "${process.env.REACT_APP_BASE_URL}/contact/campaigns?id=${audienceId}"

querystring = {"id":"{id}"}

payload = {
    "first_name": "<string>",
    "last_name": "<string>",
    "email": "<string>",
    "description": "<string>",
    "phone": "<string>"
}
headers = {
    "x-api-key": "<api-key>",
    "Content-Type": "application/json"
}

response = requests.request("POST", url, json=payload, headers=headers, params=querystring)

print(response.text)`);
        break;
      default:
        setCode("");
    }
  };
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"} className="dark:bg-navy-900">
          <ModalHeader>{t("audiences.audienceCreationAPIGuide")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div>
              <div>
                <p className="text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
                  {t("audiences.apiModalText")}
                </p>
              </div>
              <Box p={5}>
                <div className="flex justify-between">
                  <div>
                    <Select
                      value={selectedLanguage}
                      onChange={handleChange}
                      mr={2}
                    >
                      <option value="curl">CURL</option>
                      <option value="nodejs">Javascript</option>
                      <option value="python">Python</option>
                    </Select>
                  </div>
                  <div>
                    <CopyToClipboard text={code}>
                      <Button leftIcon={<FaCopy />}>
                        {t("audiences.copyText")}
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
                <div>
                  <SyntaxHighlighter
                    language={selectedLanguage}
                    style={atomDark}
                    wrapLines
                  >
                    {code}
                  </SyntaxHighlighter>
                </div>
              </Box>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApiModal;
