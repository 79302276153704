import AuthDropdown from "components/dropdown/authDropdown";
import React, { useEffect, useState } from "react";
import { GoArrowRight } from "react-icons/go";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { step1InfoListing, step1InfoSubmit } from "../../services/Auth";
import Loader from "components/Loader/Loader";
import { useColorMode } from "@chakra-ui/system";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const OnBoardingStep1 = ({ handleClick }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [name, setName] = useState("");
  const [industry, setIndustry] = useState("");
  const [objectives, setObjectives] = useState("");
  const [roleId, setRoleId] = useState("");
  const [industryId, setIndustryId] = useState("");
  const [objectivesId, setObjectivesId] = useState("");
  const [phone, setPhone] = useState("");
  const [infoListData, setInfoListData] = useState<any>([]);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { colorMode } = useColorMode();
  const handleDropdownToggle = (dropdownName: string) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  useEffect(() => {
    step1InfoListing()
      .then((response) => {
        setInfoListData(response.data);
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }, []);

  const handleSubmit = () => {
    if (phone) {
      let myPhone = convertToE164(phone);
      const isValid = isValidPhoneNumber(myPhone);
      if (!isValid) {
        toast({
          description: t("validCountryCodeMessage"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return;
      }
    }
    setLoading(true);
    const basic_info_payload = {
      industry_id: industryId,
      role_id: roleId,
      objective_id: objectivesId,
      phone: phone,
    };
    step1InfoSubmit(basic_info_payload)
      .then((response) => {
        if (response.success) {
          localStorage.setItem("phone", phone);
          setLoading(false);
          handleClick();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error :", error);
      });
  };

  return (
    <div className="mt-[30px]">
      {loading && <Loader />}
      <div className="flex items-center gap-2">
        <span className="text-[32px] font-bold text-blue-700">
          {t("onBoardingStep1.headingText")}
        </span>
        <span
          className="text-[24px]"
          style={{
            animationName: "wave",
            animationDuration: "3s",
            animationIterationCount: "infinite",
            transformOrigin: "70% 70%",
            display: "inline-block",
          }}
        >
          🤗
        </span>
      </div>
      <p className="mb-2 max-w-[600px] dark:text-white">
        {t("onBoardingStep1.caption1")}
      </p>
      <p className="mb-[16px] max-w-[600px] dark:text-white">
        {t("onBoardingStep1.caption2")}
      </p>
      <AuthDropdown
        question={"1. " + t("onBoardingStep1.fieldText1") + "*"}
        infoType="roles"
        options={infoListData}
        selectedValue={name}
        setSelectedValue={setName}
        placeholderValue={t("onBoardingStep1.roleplaceholder")}
        isOpen={openDropdown === "name"}
        toggleDropdown={() => handleDropdownToggle("name")}
        setSelectedId={setRoleId}
      />
      <AuthDropdown
        question={"2. " + t("onBoardingStep1.fieldText2") + "*"}
        infoType="industries"
        options={infoListData}
        selectedValue={industry}
        setSelectedValue={setIndustry}
        placeholderValue={t("onBoardingStep1.industryplaceholder")}
        isOpen={openDropdown === "industry"}
        toggleDropdown={() => handleDropdownToggle("industry")}
        setSelectedId={setIndustryId}
      />
      <AuthDropdown
        question={"3. " + t("onBoardingStep1.fieldText3") + "*"}
        infoType="objectives"
        options={infoListData}
        selectedValue={objectives}
        setSelectedValue={setObjectives}
        placeholderValue={t("onBoardingStep1.objectiveplaceholder")}
        isOpen={openDropdown === "objectives"}
        toggleDropdown={() => handleDropdownToggle("objectives")}
        setSelectedId={setObjectivesId}
      />
      <div className="mb-2 mt-[16px] flex w-full flex-col">
        <div className="mb-1 font-bold text-[#00000099] dark:text-white">
          {"4. " + t("onBoardingStep1.fieldText4") + "*"}
        </div>
        <PhoneInput
          inputProps={{
            name: "phone",
            required: true,
            className:
              "pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] max-w-[500px] items-center gap-x-2 rounded border border-gray-200 bg-white px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-500 focus:bg-white focus:outline-none",
          }}
          buttonStyle={{
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
          inputStyle={{
            background: colorMode === "dark" ? "#0B1437" : "#ffffff",
            color: colorMode === "dark" ? "white" : "black",
          }}
          dropdownStyle={{
            background: colorMode === "dark" ? "#0B1437" : "#ffffff",
            color: colorMode === "dark" ? "white" : "black",
          }}
          country={"us"}
          value={phone}
          onChange={(value, country, event, formattedValue) => {
            let myPhone = convertToE164(formattedValue);
            setPhone(myPhone);
          }}
        />
      </div>
      <button
        onClick={handleSubmit}
        disabled={
          industryId === "" ||
          objectivesId === "" ||
          roleId === "" ||
          phone === ""
        }
        className={`${
          industryId === "" ||
          objectivesId === "" ||
          roleId === "" ||
          phone === ""
            ? "bg-[#0000001f] text-[#00000042] dark:bg-gray-600"
            : "bg-blue-700 hover:bg-blue-800 active:bg-blue-800"
        } linear bg-bg-blue-700 mt-8 flex w-full max-w-[180px] items-center justify-center gap-2 rounded px-[16px]  py-[6px] text-base font-medium text-white transition duration-200`}
      >
        {t("onBoardingStep1.continueText")}{" "}
        <GoArrowRight style={{ fontSize: "20px" }} />
      </button>
    </div>
  );
};

export default OnBoardingStep1;
