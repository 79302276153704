/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { playGroundCall } from "services/playground";
import { useColorMode, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { getAssitantList } from "services/assitant";
import { convertToE164, isValidPhoneNumber } from "utils/utils";
import { usePrompt } from "hooks/usePrompt";
import { useTranslation } from "react-i18next";
interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  assistantId: string;
}
let allInitialValues: FormValues = {
  firstName: "",
  lastName: "",
  email: "",
  contactNumber: "",
  assistantId: "",
};
const Playground = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [refresh, setRefresh] = useState(false);
  const [assistant, setAssistant] = useState([]);
  const [isRequest, setIsRequest] = useState(false);
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    getAssitantList()
      .then((response: any) => {
        if (response.success) {
          setAssistant(response.data);
        } else {
          if (response?.message === "No Assistant Found") setAssistant([]);
        }
      })
      .catch((error: string) => {
        console.error("Error :", error);
      });
  }, [refresh]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("assistantForm.fNameValid")),
    lastName: Yup.string().required(t("assistantForm.lNameValid")),
    email: Yup.string()
      .email(t("assistantForm.emailValid1"))
      .required(t("assistantForm.emailValid")),
    assistantId: Yup.string().required(t("assistantForm.assistantValid")),
    contactNumber: Yup.string().required(t("assistantForm.phoneNumberValid")),
  });
  const formik = useFormik({
    initialValues: allInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const selectedAssist = assistant.find(
        (item) => item?.id === values.assistantId
      );
      let mobileNumbers = values.contactNumber.split("\n");

      const isValid = mobileNumbers.every((number: any) =>
        isValidPhoneNumber(number)
      );
      if (!isValid) {
        toast({
          description: t("assistantForm.contactNumberValid"),
          status: "error",
          duration: 8000,
          isClosable: true,
          position: "top-left",
        });
        return;
      }
      setIsRequest(true);
      const payload = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.email,
        assistant_id: values?.assistantId,
        phone_number: values.contactNumber.split("\n"),
        language: selectedAssist.language,
        start_speech: selectedAssist.start_speech?.replace(/<[^>]*>/g, ""),
        script: selectedAssist.script?.replace(/<[^>]*>/g, ""),
        is_start_speech_update: false,
        start_speech_wav: selectedAssist.start_speech_wav,
        maximum_time_per_call: selectedAssist.maximum_time_per_call,
        actions: selectedAssist.actions,
        gender: selectedAssist.gender,
        voice_name: selectedAssist.voice_name,
        voice_id: selectedAssist?.voice_id,
        voice_note: selectedAssist.voice_note,
        goal: selectedAssist.goal,
        knowledge_base_Id: selectedAssist.knowledge_base_id,
        is_recording: selectedAssist?.is_recording,
        is_voice_note: selectedAssist?.is_voice_note,
        zapier_hook: selectedAssist.zapier_hook,
        is_back_sound: selectedAssist?.is_back_sound,
        voice_speed: selectedAssist?.voice_speed,
        is_filler: selectedAssist?.is_filler,
        fillers: selectedAssist?.fillers,
      };
      playGroundCall(payload)
        .then((response) => {
          setIsRequest(false);
          if (response?.success) {
            setRefresh(!refresh);
            toast({
              description: t("messages.playgroundSubmit"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
            setIsRender(!isRender);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });

  let isDirty = formik.dirty && formik.submitCount === 0;
  usePrompt({
    isDirty,
  });
  return (
    <div className="m-auto max-w-[900px]">
      {isRequest && <Loader />}
      <div className="mt-4">
        <div className="mt-3 flex flex-col gap-6 md:flex-row">
          <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
            <label className="  dark:text-white" htmlFor="Start Speech">
              {t("assistantForm.fName")} *
            </label>
            <input
              id="firstName"
              type="text"
              value={formik?.values.firstName}
              onChange={formik.handleChange}
              className="h-full w-full rounded border border-gray-500 p-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-red-500">
                {formik.errors.firstName.toString()}
              </div>
            ) : null}
          </div>
          <div className="mt-4 flex w-full flex-col gap-1 md:w-[50%]">
            <label className="  dark:text-white" htmlFor="Start Speech">
              {t("assistantForm.lName")} *
            </label>
            <input
              id="lastName"
              type="text"
              value={formik?.values.lastName}
              onChange={formik.handleChange}
              className="h-full w-full rounded border border-gray-500 p-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-red-500">
                {formik.errors.lastName.toString()}
              </div>
            ) : null}
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-1 rounded-lg">
          <label className="  dark:text-white" htmlFor="Start Speech">
            {t("assistantForm.email")} *
          </label>
          <input
            id="email"
            type="email"
            value={formik?.values.email}
            onChange={formik.handleChange}
            className="h-full w-full rounded border border-gray-500 p-4 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email.toString()}</div>
          ) : null}
        </div>
        <div className="mt-4 flex flex-col gap-1 rounded-lg">
          <label
            className="  dark:text-white"
            htmlFor={t("assistantForm.phoneNumber")}
          >
            {t("assistantForm.phoneNumber")} *
          </label>
          <PhoneInput
            inputStyle={{
              background: colorMode === "dark" ? "#0B1437" : "#ffffff",
              color: colorMode === "dark" ? "white" : "black",
            }}
            dropdownStyle={{
              background: colorMode === "dark" ? "#0B1437" : "#ffffff",
              color: colorMode === "dark" ? "white" : "black",
            }}
            inputProps={{
              name: "contactNumber",
              id: "contactNumber",
              required: true,
              className:
                "h-[55px] pl-[50px] dark:bg-slate-900 inline-flex w-full border-[1px] items-center gap-x-2 rounded border border-gray-500 bg-white focus:border-blue-500 px-4 py-3 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white focus:border-blue-[700] focus:bg-white focus:outline-none",
            }}
            buttonStyle={{
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
            country={"us"}
            onChange={(value, country, event, formattedValue) => {
              let myPhone = convertToE164(formattedValue);
              formik.setFieldValue("contactNumber", myPhone);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.contactNumber}
          />
          {formik.touched.contactNumber && formik.errors.contactNumber ? (
            <div className="text-red-500">
              {formik.errors.contactNumber.toString()}
            </div>
          ) : null}
        </div>
        <div className="mt-4 flex flex-col gap-1 rounded-lg">
          <label className="  dark:text-white" htmlFor="Prompt">
            {t("assistantForm.selectAssistant")}
          </label>
          <select
            id="assistantId"
            value={formik.values?.assistantId}
            onChange={formik.handleChange}
            className="h-full w-full rounded border-[1px] border-gray-500 p-4 text-[18px] leading-tight focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
          >
            <option value="">{t("assistantForm.selectAssistantOption")}</option>
            {assistant?.map((item: any, index: number) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          {formik.touched.assistantId && formik.errors.assistantId ? (
            <div className="text-red-500">
              {formik.errors.assistantId.toString()}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-5 flex w-full  items-start justify-center pt-4">
        <button
          className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          {t("submitText")}
        </button>
      </div>
    </div>
  );
};

export default Playground;
