import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Loader from "components/Loader/Loader";
import { changePassword } from "services/Auth";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
const ChangePasswordModal = ({ onClose, isOpen }: any) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const changePassInitialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const changePassValidationSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(8, t("changePassword.oldPasswordValid2"))
      .required(t("changePassword.oldPasswordValid")),
    new_password: Yup.string()
      .min(8, t("changePassword.newPasswordValid2"))
      .notOneOf(
        [Yup.ref("old_password")],
        t("changePassword.newPasswordValid3")
      )
      .required(t("changePassword.newPasswordValid")),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("new_password"), null],
        t("changePassword.confirmPasswordValid2")
      )
      .required(t("changePassword.confirmPasswordValid")),
  });
  const handleSubmitForm = (value: any) => {
    setLoading(true);
    const payload = {
      oldPassword: value?.old_password,
      newPassword: value?.new_password,
    };
    changePassword(payload)
      .then((response) => {
        if (response?.success) {
          toast({
            description: t("changePasswordUpdated"),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
          onClose();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  return (
    <div>
      {loading && <Loader />}
      <Drawer
        placement="right"
        onClose={onClose}
        size={"md"}
        isOpen={isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent borderRadius={8} className="dark:bg-navy-900">
          <DrawerHeader>{t("changePassword.changePassword")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody padding={"20px 24px"}>
            <Formik
              initialValues={changePassInitialValues}
              validationSchema={changePassValidationSchema}
              onSubmit={(values: any) => {
                handleSubmitForm(values);
              }}
            >
              <Form>
                <InputField
                  variant="auth"
                  name="old_password"
                  extra="mb-3"
                  label={`${t("changePassword.oldPassword")} *`}
                  placeholder={t("changePassword.minChar")}
                  id="old_password"
                  type="password"
                />
                <InputField
                  variant="auth"
                  name="new_password"
                  extra="mb-3"
                  label={`${t("changePassword.newPassword")} *`}
                  placeholder={t("changePassword.minChar")}
                  id="new_password"
                  type="password"
                />
                <InputField
                  variant="auth"
                  name="confirm_password"
                  extra="mb-3"
                  label={`${t("changePassword.confirmPassword")} *`}
                  placeholder={t("changePassword.minChar")}
                  id="confirm_password"
                  type="password"
                />

                <div className="mt-10 flex items-center justify-start gap-4 ">
                  <button
                    type="button"
                    onClick={onClose}
                    className="linear text-black rounded border bg-[#edf2f7] px-[16px] py-[6px] text-base font-medium transition duration-200 hover:bg-[#dbdee1] dark:border-blue-700 dark:bg-navy-900 dark:text-blue-700"
                  >
                    {t("cancel")}
                  </button>
                  <button
                    type="submit"
                    className="linear flex items-center justify-center gap-2 rounded bg-blue-700 px-[16px] py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800  active:bg-blue-800 dark:bg-blue-700 "
                  >
                    {t("submitText")}
                  </button>
                </div>
              </Form>
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default ChangePasswordModal;
