import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { IoMdSend } from "react-icons/io";
import Typewriter from "typewriter-effect";
import { SendMassege, ResiveMassage } from "services/chatScript";
import { FaCopy } from "react-icons/fa";
import TooltipHorizon from "components/tooltip";
import { SiPurescript } from "react-icons/si";
import { useTranslation } from "react-i18next";

const ChatModal = ({
  isOpen,
  onClose,
  allValues,
  status,
  setSelectedTemplate,
}: // openingCall,
any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const chatContainerRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input field
  const [message, setMessage] = useState("");
  const [isSendMessage, setIsSendMessage] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [finalScript, setFinalScript] = useState("");
  const [messages, setMessages] = useState([]);
  const [hashId, setHashId] = useState("");
  const [copiedKeyIndex, setCopiedKeyIndex] = useState(null);

  useEffect(() => {
    if (isOpen) {
      // let payload = {
      //   title: scriptTitle,
      // };
      let payload = {
        goal: allValues.goal,
        language: allValues.language,
        company_name: status === "assistant" ? allValues.company_name : "",
        name: status === "assistant" ? allValues.name : "",
      };
      setIsLoadingMessage(true);
      ResiveMassage(payload)
        .then((response) => {
          if (response.success) {
            setIsLoadingMessage(false);
            let agentData = response.data;
            let dd = {
              role: "assistant",
              content: agentData.message,
            };
            setMessages((prevMessages) => [...messages, dd]);
            setHashId(agentData.id);
          }
        })
        .catch((error) => {
          setIsLoadingMessage(false);
          console.error("Error submitting feedback:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoadingMessage]);

  const handleMessageSend = () => {
    if (message.trim() === "") return;
    const newMessage = {
      sender: "User",
      text: message,
      time: getCurrentTime(),
    };
    setIsSendMessage(true);
    setMessages([...messages, newMessage]);
    setMessage("");
    scrollToBottom();
    let request = {
      id: hashId,
      text: message,
    };
    let myReq = {
      content: message,
      role: "user",
    };
    setMessages((prevMessages) => [...messages, myReq]);
    setIsLoadingMessage(false);
    sendMessage(request);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 500);
  };

  const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
  };

  const scrollToBottom = () => {
    // if (chatContainerRef.current) {
    //   chatContainerRef.current.scrollTop =
    //     chatContainerRef.current.scrollHeight;
    // }
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 500);
  }, [isOpen]);
  const removeTags = (html: any) => {
    return html.replace(/<[^>]*>/g, "");
  };
  const sendMessage = (payload: any) => {
    setIsLoadingMessage(true);
    SendMassege(payload)
      .then((response) => {
        if (response.success) {
          let lastElement = response.data[response.data.length - 1];
          if (
            lastElement?.content?.includes("<body>") &&
            lastElement?.content?.includes("</body>")
          ) {
            // Extract content within <body> tags
            const bodyContent = lastElement?.content?.match(
              /<body[^>]*>([\s\S]*?)<\/body>/
            )[1];
            const cleanedContent = bodyContent.replace(/\n\n/g, "<p><br/></p>");
            const cleanedContent2 = cleanedContent.replace(
              /\n/g,
              "<p><br/></p>"
            );
            // let remainingContent = "";
            // const paragraphs = cleanedContent2?.match(/<p>.*?<\/p>/g);
            // if (paragraphs && paragraphs.length > 0) {
            //   // Check if the first paragraph is <p><br/></p>
            //   for (let i = 0; i < Math.min(2, paragraphs.length); i++) {
            //     if (paragraphs[i] === "<p><br/></p>") {
            //       paragraphs.splice(i, 1);
            //     }
            //   }
            //   remainingContent = paragraphs.join("");
            // }

            lastElement.content = cleanedContent2;
            setFinalScript(cleanedContent2);
            // setFirstParagraph(firstP);
          }
          setMessages(response.data);
          setIsLoadingMessage(false);
          setIsSendMessage(false);
          setTimeout(() => {
            inputRef?.current?.focus();
          }, 500);
        }
      })
      .catch((error) => {
        setIsLoadingMessage(false);
        console.error("Error submitting feedback:", error);
      });
  };
  const profileImage = localStorage.getItem("profileImage");
  const modalClose = () => {
    setMessage("");
    setIsSendMessage(false);
    setIsLoadingMessage(false);
    setMessages([]);
    setHashId("");
    setFinalScript("");
    onClose();
  };

  const handleSelectScript = () => {
    // openingCall(firstParagraph);
    setSelectedTemplate(finalScript);
    resetAllData();
  };
  const resetAllData = () => {
    setMessage("");
    setIsSendMessage(false);
    setIsLoadingMessage(false);
    setMessages([]);
    setHashId("");
    onClose();
    setFinalScript("");
  };
  const handleCopyClick = (text: any, index: any) => {
    let code = removeTags(text);
    navigator.clipboard.writeText(code);
    setCopiedKeyIndex(index);
    setTimeout(() => setCopiedKeyIndex(null), 2000); // Reset copied key index after 2 seconds
  };
  const handleCopyClickUseScript = (text: any) => {
    setSelectedTemplate(text);
    resetAllData();
  };
  return (
    <div>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={modalClose}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent
          maxW={"1400px"}
          minH={"95%"}
          className="dark:bg-navy-900"
          mx={8}
        >
          <ModalHeader>{t("assistantForm.generativeScript")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex h-full flex-col justify-start space-y-4 overflow-auto  p-6 sm:min-h-[200px] xxl:min-h-[500px]">
              {/* Chat messages go here */}
              <div className="flex flex-col gap-5 space-y-1">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`relative flex flex-row items-start gap-2 ${
                      msg.role === "user" ? "flex-row-reverse" : ""
                    }`}
                  >
                    <Avatar
                      size="sm"
                      name={msg.role}
                      className="border"
                      borderColor={"blue.500"}
                      src={msg.role === "user" ? profileImage : ""}
                    />
                    <div className="flex flex-col">
                      <div
                        className={`relative min-w-[200px] max-w-[800px] rounded-lg ${
                          msg.role === "user"
                            ? "border border-blue-700 text-[#000000] dark:text-white"
                            : "bg-blue-700  text-white"
                        } p-2`}
                      >
                        <div className="flex items-center">
                          <span className="font-semibold">
                            {" "}
                            {msg?.role === "assistant" ? (
                              <Typewriter
                                options={{
                                  delay: 0,
                                  cursor: "",
                                }}
                                onInit={(typewriter) => {
                                  typewriter
                                    .typeString(
                                      msg.content.replace(/\n/g, "<br/>")
                                    )
                                    .callFunction(() => {
                                      console.log("String typed out!");
                                      scrollToBottom();
                                    })
                                    .start();
                                }}
                              />
                            ) : (
                              <>{msg?.content}</>
                            )}
                          </span>
                        </div>
                      </div>
                      {msg.role !== "user" && (
                        <div className="pl-2 pt-2">
                          <TooltipHorizon
                            trigger={
                              <span>
                                <button
                                  className="text-gray-600"
                                  onClick={() =>
                                    handleCopyClick(msg?.content, index)
                                  }
                                >
                                  {copiedKeyIndex === index ? (
                                    t("assistantForm.copiedText")
                                  ) : (
                                    <FaCopy />
                                  )}
                                </button>
                              </span>
                            }
                            placement="top"
                            content={t("assistantForm.copyText")}
                            extra="rounded text-[11px]"
                          />
                          <TooltipHorizon
                            trigger={
                              <span>
                                <button
                                  className="ml-3 text-xl text-gray-600"
                                  onClick={() =>
                                    handleCopyClickUseScript(msg?.content)
                                  }
                                >
                                  <SiPurescript />
                                </button>
                              </span>
                            }
                            placement="top"
                            content={t("assistantForm.useThisAsScript")}
                            extra="rounded text-[11px]"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {isLoadingMessage && (
                  <div className="flex">
                    <span>{t("assistantForm.typingText")}</span>
                    <div className="typing-loader"></div>
                  </div>
                )}
                <div ref={chatContainerRef} />
              </div>
              {/* End of chat messages */}
            </div>
          </ModalBody>
          <ModalFooter width={"100%"} pt={0}>
            {!finalScript ? (
              <div className="relative w-full">
                <textarea
                  ref={inputRef}
                  placeholder={t("assistantForm.typeMsg")}
                  className="textarea-resize w-full rounded border-[1px] border-gray-500 px-4 py-2 pr-20 text-[16px] leading-tight focus:border-blue-700 focus:bg-white focus:outline-none  dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  disabled={isSendMessage}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleMessageSend();
                    }
                  }}
                />
                <button
                  onClick={handleMessageSend}
                  disabled={isSendMessage}
                  className="send-message-btn"
                >
                  <IoMdSend className=" ml-2 h-8 w-8 cursor-pointer text-blue-500" />
                </button>
                {/* <button onClick={() => inputRef?.current?.focus()}>
                  {" "}
                  inputRef?.current?.focus
                </button> */}
              </div>
            ) : (
              <div className="mt-3 flex w-full justify-center text-center">
                <button
                  className={
                    "linear flex w-fit items-center justify-center gap-2 rounded  bg-blue-700 px-[16px] py-[6px] text-base font-medium text-white transition duration-200  hover:bg-blue-800 active:bg-blue-800"
                  }
                  onClick={handleSelectScript}
                >
                  {t("assistantForm.useThisScript")}
                </button>
              </div>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ChatModal;
