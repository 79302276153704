/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import { updateContactList } from "services/contact";
import SaveConfirmModal from "./SaveConfirmModal";
import { useTranslation } from "react-i18next";

const ContactEditModal = ({
  isOpen,
  handleClose,
  rowData,
  setContactData,
  setRefresh,
  refresh,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [isOpenWarning, setIsOpenWarning] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("segments.name")),
    description: Yup.string().required(t("segments.description")),
  });

  const formik = useFormik({
    initialValues: {
      name: rowData?.name || "",
      description: rowData?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const payload = {
        name: values?.name,
        description: values?.description,
      };
      updateContactList(payload, rowData?.id).then((response: any) => {
        if (response.status === 200) {
          setRefresh(!refresh);
          toast({
            description: t("messages.segmentUpdated"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          handleClose();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      });
    },
  });

  useEffect(() => {
    if (rowData && isOpen) {
      formik.setValues({
        name: rowData?.name || "",
        description: rowData?.description || "",
      });
    }
  }, [rowData, isOpen]);

  const checkIsDirtyForm = () => {
    let initialValue = {
      name: rowData?.name || "",
      description: rowData?.description || "",
    };
    let isDirty =
      JSON.stringify(initialValue) !== JSON.stringify(formik?.values);
    if (isDirty) {
      openWarningModal();
    } else {
      formik.resetForm();
      handleClose();
    }
  };
  const openWarningModal = () => {
    setIsOpenWarning(true);
  };
  const closeWarningModal = () => {
    setIsOpenWarning(false);
  };
  const onConfirm = () => {
    handleClose();
    formik.resetForm();
    setIsOpenWarning(false);
  };
  const onCancel = () => {
    setIsOpenWarning(false);
  };
  return (
    <>
      <Drawer
        placement="right"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={checkIsDirtyForm}
        // scrollBehavior={"inside"}
        blockScrollOnMount={false}
        size={"sm"}
      >
        <DrawerOverlay />
        <DrawerContent className="dark:bg-navy-900">
          <DrawerHeader>{t("segments.editSegment")}</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody pb={6}>
            <FormControl>
              <FormLabel>{t("segments.name")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2  leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name ? (
                <div>{formik.errors.name.toString()}</div>
              ) : null}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("segments.description")}*</FormLabel>
              <input
                className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2  leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
                id="description"
                type="text"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
              {formik.touched.description && formik.errors.description ? (
                <div>{formik.errors.description.toString()}</div>
              ) : null}
            </FormControl>
          </DrawerBody>

          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => formik.handleSubmit()}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("save")}
            </Button>
            <Button onClick={checkIsDirtyForm}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SaveConfirmModal
        isOpen={isOpenWarning}
        onClose={closeWarningModal}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default ContactEditModal;
