import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TemplatesModal = ({ isOpen, onClose, handleTemplateSubmit }: any) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const templateModalData: any = [
    {
      heading: t("assistantForm.templateData.hr_pre_screening_outbound"),
      description: t("assistantForm.templateData.hr_description"),
    },
    {
      heading: t("assistantForm.templateData.real_estate_seller_inquiry"),
      description: t(
        "assistantForm.templateData.real_estate_seller_description"
      ),
    },
    {
      heading: t("assistantForm.templateData.real_estate_buyer_inquiry"),
      description: t(
        "assistantForm.templateData.real_estate_buyer_description"
      ),
    },
    {
      heading: t("assistantForm.templateData.solar_renewable_energy"),
      description: t(
        "assistantForm.templateData.solar_renewable_energy_description"
      ),
    },
    {
      heading: t("assistantForm.templateData.insurance_policy_sales"),
      description: t(
        "assistantForm.templateData.insurance_policy_sales_description"
      ),
    },
  ];
  const handleSelectScript = () => {
    handleTemplateSubmit(templateModalData[selectedTab].description);

    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event: "Precallai Template",
        buttonName: "Have Precallai Customize This Template",
      });
    }
  };
  return (
    <Drawer
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      size={"xl"}
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerContent maxW={"1400px"} h={"100%"} className="dark:bg-navy-900">
        <DrawerHeader textAlign={"center"}>
          {t("assistantForm.previewSelectedScript")}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody overflow={"hidden"} className="flex">
          <div className="w-1/4 overflow-auto border-r border-gray-200 dark:border-gray-700">
            <div className={`p-4 font-bold`}>
              {t("assistantForm.templates")} :
            </div>
            <hr />
            {templateModalData.map((tab: any, index: number) => (
              <div key={index}>
                <div
                  className={`cursor-pointer p-4 ${
                    selectedTab === index && "bg-blue-50 dark:text-[#000000]"
                  }`}
                  onClick={() => setSelectedTab(index)}
                >
                  {tab.heading}
                </div>
                <hr />
              </div>
            ))}
          </div>
          <div className="w-3/4 p-4">
            {/* <h3>{templateModalData[selectedTab].heading}</h3> */}
            {/* <textarea
              className="h-full w-full bg-[transparent] p-3"
              readOnly
              disabled
              value={templateModalData[selectedTab].description}
            /> */}
            <div
              className="h-full w-full overflow-auto bg-[transparent] p-3"
              dangerouslySetInnerHTML={{
                __html: templateModalData[selectedTab].description,
              }}
            ></div>
          </div>
        </DrawerBody>
        <div className="flex justify-center p-3">
          <button
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            onClick={handleSelectScript}
          >
            {t("assistantForm.customizeTemplate")}
          </button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default TemplatesModal;
