/*eslint-disable*/
import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex text-center">
      <div className="px-4">
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: t("termsOfService.agreement", {
                termsLink: `<a href="https://www.precallai.com/terms-conditions" target="_blank" rel="noopener noreferrer" style="color: #14bfff;">${t(
                  "termsOfService.termsOfService"
                )}</a>`,
                privacyLink: `<a href="https://www.precallai.com/privacy-policy" target="_blank" rel="noopener noreferrer" style="color: #14bfff;">${t(
                  "termsOfService.privacyPolicy"
                )}</a>`,
              }),
            }}
          />
        </p>
      </div>
    </div>
  );
}
