import React, { useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { startCampaign } from "services/campaign";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewCpModal = ({
  isOpen,
  handleClose,
  setRefresh,
  refresh,
  rowData,
  campaignsData,
}: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const handleClick = () => {
    startCampaign(rowData)
      .then((response: any) => {
        if (response.status === 200) {
          setRefresh(!refresh);
          handleClose();
          let campData = campaignsData;
          campData.is_started = true;
          navigate("/admin/campaigns-detail", {
            state: { rowData: campData },
          });
          toast({
            description: t("messages.CAMPAIGN_STARTED"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        } else {
          handleClose();
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error: string) => {
        handleClose();
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size="xxl"
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"} className="dark:bg-navy-900">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <div className="w-full border-b border-gray-500"></div>
          <ModalBody pb={6}>
            <div className="w-full">
              <div>
                <h2 className="text-2xl font-medium">
                  {t("campaign.doYouWantStart")}{" "}
                  <span className="cursor-pointer text-blue-700">
                    {campaignsData?.campaigns_name}
                  </span>{" "}
                  {t("campaign.campaign")} ?
                </h2>
                <p className="font-sm text-lg">
                  {t("campaign.uponStartingCampaign")}{" "}
                  <span className="text-blue-700">
                    {campaignsData?.campaigns_name}
                  </span>
                  .
                </p>
              </div>
              <div className="mt-4 flex items-center justify-center gap-6">
                <button
                  onClick={() => handleClose()}
                  className="flex items-center justify-center rounded border border-blue-700  bg-white px-5 py-2 uppercase text-blue-700 dark:bg-navy-900"
                >
                  {t("cancel")}
                </button>
                <button
                  onClick={() => handleClick()}
                  className="flex items-center justify-center rounded border border-blue-700  bg-white px-7 py-2 uppercase text-blue-700 dark:bg-navy-900"
                >
                  {t("confirm")}
                </button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewCpModal;
