import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBackIos } from "react-icons/md";
import { useDropzone } from "react-dropzone";
// import Papa from "papaparse";
import { SlCloudUpload } from "react-icons/sl";
import { uploadKnowledgeBase } from "services/knowledgeBase";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";
const AddEditknowlageBaseModal = ({
  isOpen,
  handleClose,
  questionsAnswers,
  formikSet,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const toast = useToast();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectionPage, setIsSelectionPage] = useState(false);
  const validationSchema = Yup.object().shape({
    question: Yup.string().required(t("knowledgeBase.questionValid")),
    answer: Yup.string()
      .required(t("knowledgeBase.ansValid"))
      .max(250, t("knowledgeBase.charValid")),
  });

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        question: values?.question,
        answer: values?.answer,
      };
      const mergedArray = [...questionsAnswers, payload];
      formikSet.setFieldValue("data", mergedArray);
      closeKnowladgeBase();
    },
  });
  const closeKnowladgeBase = () => {
    setSelectedValue("1");
    setIsSelectionPage(false);
    formik.resetForm();
    handleClose();
  };
  const changeRadio = (value: any) => {
    setSelectedValue(value);
  };
  const selectOptions = () => {
    setIsSelectionPage(true);
  };
  const goBackToMain = () => {
    setSelectedValue("1");
    setIsSelectionPage(false);
    formik.resetForm();
  };
  const handleFileUpload = (acceptedFiles: File[]) => {
    setIsLoading(true);
    uploadKnowledgeBase(acceptedFiles)
      .then((response) => {
        setIsLoading(false);
        if (response.success) {
          let resData = response?.faqs;
          if (resData?.FAQs) {
            const mergedArray = [...questionsAnswers, ...resData?.FAQs];
            formikSet.setFieldValue("data", mergedArray);
          }
          if (resData?.faqs) {
            const mergedArray = [...questionsAnswers, ...resData?.faqs];
            formikSet.setFieldValue("data", mergedArray);
          }
          closeKnowladgeBase();
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    multiple: false,
    accept: {
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    maxSize: 10 * 1024 * 1024,
  });
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => {
          closeKnowladgeBase();
        }}
        size={"xxl"}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"} className="dark:bg-navy-900">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {isLoading && <Loader />}
            {!isSelectionPage && (
              <div>
                <div className="p-2">
                  <div className="space-y-5">
                    <h3 className="block w-full text-center text-3xl">
                      {t("knowledgeBase.addKnowledgeBase")}
                    </h3>
                    <p className="text-center">
                      {t("knowledgeBase.addKnowledgeBaseCaption")}
                    </p>
                    <div id="headlessui-radiogroup-:r4n:" role="radiogroup">
                      <div
                        className="grid grid-cols-1 bg-white sm:grid-cols-2 md:grid-cols-2"
                        role="none"
                      >
                        <div
                          className={
                            (selectedValue === "1"
                              ? "z-10 rounded-tl-md rounded-tr-md border-blue-200 bg-blue-50 "
                              : "rounded-bl-md rounded-br-md border-gray-200") +
                            " " +
                            "relative flex cursor-pointer items-center justify-center  border px-4 py-6 focus:outline-none"
                          }
                          onClick={() => changeRadio("1")}
                        >
                          <span className="ml-3 flex flex-col">
                            <span
                              className="block text-sm font-medium text-gray-900"
                              id="headlessui-label-:r4r:"
                            >
                              {t("knowledgeBase.manuallyAdd")}
                            </span>
                          </span>
                        </div>
                        <div
                          className={
                            (selectedValue === "2"
                              ? "z-10 rounded-tl-md rounded-tr-md border-blue-200 bg-blue-50 "
                              : "rounded-bl-md rounded-br-md border-gray-200") +
                            " " +
                            "relative flex cursor-pointer items-center justify-center  border px-4 py-6 focus:outline-none"
                          }
                          onClick={() => changeRadio("2")}
                        >
                          <span className="ml-3 flex flex-col">
                            <span
                              className="block text-sm font-medium text-blue-900"
                              id="headlessui-label-:r4p:"
                            >
                              {t("knowledgeBase.uploadDoc")}
                              <br />
                              {t("knowledgeBase.uploadDoc1")}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isSelectionPage && selectedValue === "2" && (
              <div className="p-2">
                <div className="mb-6 flex items-start justify-between">
                  <button
                    title="Back"
                    className="w-7"
                    onClick={() => goBackToMain()}
                  >
                    <MdArrowBackIos className="h-[28px] w-[28px]" />
                  </button>
                  <div className="text-center">
                    <h2 className="mb-2 text-3xl font-bold text-blue-500">
                      {t("knowledgeBase.uploadDoc2")}
                    </h2>
                    <p className="mx-auto max-w-[400px] text-center">
                      {t("knowledgeBase.uploadDoc3")}
                    </p>
                  </div>
                  <span>&nbsp;</span>
                </div>
                <div className="mt-4">
                  <section>
                    <div {...getRootProps()}>
                      <div className="flex w-full items-center justify-center">
                        <label
                          htmlFor="dropzone-file"
                          className="flex  h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100  dark:bg-navy-900 "
                        >
                          <div className="flex flex-col items-center justify-center pb-6 pt-5">
                            <span className="mb-4  font-bold text-gray-500">
                              <SlCloudUpload className="h-16 w-16" />
                            </span>
                            <p className="mb-2 text-sm font-semibold text-gray-500 ">
                              {t("knowledgeBase.uploadFile")}
                            </p>
                            <p className="text-xs text-gray-500 ">
                              {t("knowledgeBase.uploadFile2")}
                            </p>
                          </div>
                          <input {...getInputProps()} />
                        </label>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            )}
            {isSelectionPage && selectedValue === "1" && (
              <div>
                <div className="mb-6 flex items-center justify-between">
                  <button
                    title="Back"
                    className="w-7"
                    onClick={() => goBackToMain()}
                  >
                    <MdArrowBackIos className="h-[28px] w-[28px]" />
                  </button>
                  <h3 className="block w-full text-center text-3xl">
                    {t("knowledgeBase.createFlashCard")}
                  </h3>
                  <span>&nbsp;</span>
                </div>
                <FormControl>
                  <FormLabel>{t("knowledgeBase.question")} *</FormLabel>
                  <input
                    className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="question"
                    name="question"
                    type="text"
                    placeholder={t("knowledgeBase.placeholder1")}
                    value={formik.values.question}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.question && formik.errors.question ? (
                    <div className="text-red-500">
                      {formik.errors.question.toString()}
                    </div>
                  ) : null}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{t("knowledgeBase.answer")} *</FormLabel>
                  <textarea
                    className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                    id="answer"
                    name="answer"
                    rows={3}
                    placeholder={t("knowledgeBase.placeholder2")}
                    value={formik.values.answer}
                    onChange={(e) => {
                      if (e.target.value.length <= 250) {
                        formik.setFieldValue(
                          "answer",
                          e.target.value.slice(0, 250)
                        );
                      }
                    }}
                  ></textarea>
                  <div
                    className={
                      formik.touched.answer && formik.errors.answer
                        ? "flex justify-between gap-3"
                        : "flex justify-end gap-3"
                    }
                  >
                    {formik.touched.answer && formik.errors.answer ? (
                      <div className="text-red-500">
                        {formik.errors.answer.toString()}
                      </div>
                    ) : null}
                    <div className="text-right text-[14px] text-gray-700">
                      {formik.values.answer.length}/250{" "}
                      {t("knowledgeBase.characters")}
                    </div>
                  </div>
                </FormControl>
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {!isSelectionPage && (
              <Button
                colorScheme="blue"
                type="submit"
                mr={3}
                onClick={() => selectOptions()}
                className="dark:bg-blue-700 dark:text-white"
              >
                {t("knowledgeBase.select")}
              </Button>
            )}
            {isSelectionPage && selectedValue === "1" && (
              <Button
                colorScheme="blue"
                type="submit"
                mr={3}
                onClick={() => formik.handleSubmit()}
                className="dark:bg-blue-700 dark:text-white"
              >
                {t("save")}
              </Button>
            )}
            {/* <Button
              onClick={() => {
                closeKnowladgeBase();
              }}
            >
              Cancel
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddEditknowlageBaseModal;
