import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import countries from "assets/data/countries.json";
import premiumIcon from "assets/img/premium.png";
import { getLanguageKeyByValue } from "utils/utils";
import { useTranslation } from "react-i18next";
const GenderAccentModal = ({
  onClose,
  isOpen,
  voiceList,
  selectedVoiceData,
  setSelectedVoiceData,
  selectedLanguage,
}: any) => {
  const { t } = useTranslation();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [filterGender, setFilterGender] = useState("");
  const [filterAccent, setFilterAccent] = useState("");
  const [filterLanguage, setFilterLanguage] = useState("");
  const [filterPremium, setFilterPremium] = useState("");

  const groupedVoicesByAccent = voiceList.reduce((acc: any, voice: any) => {
    acc[voice.accent] = acc[voice.accent] || [];
    acc[voice.accent].push(voice);
    return acc;
  }, {});

  // Extract group keys as accent options
  const accentOptions = Object.keys(groupedVoicesByAccent);

  const groupedLanguage = voiceList.reduce((acc: any, voice: any) => {
    acc[voice.language] = acc[voice.language] || [];
    acc[voice.language].push(voice);
    return acc;
  }, {});
  // Extract group keys as accent options
  const languageOptions = Object.keys(groupedLanguage);

  useEffect(() => {
    if (selectedVoiceData) {
      setSelectedOption(selectedVoiceData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVoiceData]);
  useEffect(() => {
    if (isOpen && selectedLanguage) {
      setFilterLanguage(getLanguageKeyByValue(selectedLanguage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedLanguage]);
  const handleOptionSelect = (option: any) => {
    setSelectedOption(option);
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
    const audio = new Audio(option.voice_sample);
    audio.play();
    setAudioElement(audio);
  };
  const isOptionSelected = (option: any) => {
    return selectedOption && selectedOption.id === option.id;
  };
  const handleChooseItem = () => {
    setSelectedVoiceData(selectedOption);
    setFilterGender("");
    setFilterAccent("");
    onClose();
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const handleCloseAccent = () => {
    setAudioElement(null);
    setFilterGender("");
    setFilterAccent("");
    onClose();
    if (audioElement) {
      audioElement.pause();
      audioElement.currentTime = 0;
    }
  };
  const handleChangeLanguage = (e: any) => {
    setFilterLanguage(e.target.value);
  };
  const handleChangeGender = (e: any) => {
    setFilterGender(e.target.value);
  };
  const handleChangeAccent = (e: any) => {
    setFilterAccent(e.target.value);
  };
  const handleChangePremium = (e: any) => {
    setFilterPremium(e.target.value);
  };
  const filteredVoices = voiceList.filter((voice: any) => {
    if (filterAccent && voice.accent !== filterAccent) {
      return false;
    }
    if (filterGender && voice.gender !== filterGender) {
      return false;
    }
    if (filterLanguage && voice.language !== filterLanguage) {
      return false;
    }
    if (
      filterPremium &&
      voice.is_premium !== (filterPremium === "Premium" ? true : false)
    ) {
      return false;
    }
    return true;
  });
  return (
    <div>
      <Drawer
        placement="right"
        onClose={handleCloseAccent}
        size={"xl"}
        isOpen={isOpen}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW={"1000px"}
          borderRadius={8}
          h={"100%"}
          className="dark:bg-navy-900"
        >
          <DrawerHeader fontSize={24} textAlign={"center"}>
            {t("assistantForm.chooseGenderAccent")}
          </DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody
            overflow={"hidden"}
            textAlign={"center"}
            padding={"0px 24px 20px 24px"}
          >
            <div className="mb-2 flex gap-4">
              <select
                id="language"
                value={filterLanguage}
                onChange={handleChangeLanguage}
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                <option value="">{t("assistantForm.allLanguage")}</option>
                {languageOptions.map((language) => (
                  <option key={language} value={language}>
                    {language}
                  </option>
                ))}
              </select>
              <select
                id="gender"
                value={filterGender}
                onChange={handleChangeGender}
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                <option value="">{t("assistantForm.allGender")}</option>
                <option value={"Male"}>{t("assistantForm.male")}</option>
                <option value={"Female"}>{t("assistantForm.female")}</option>
              </select>
              <select
                id="gender"
                value={filterAccent}
                onChange={handleChangeAccent}
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                <option value={""}>{t("assistantForm.allAccent")}</option>
                {accentOptions.map((accent) => (
                  <option key={accent} value={accent}>
                    {countries[accent as keyof typeof countries]}
                  </option>
                ))}
                {/* <option value=""></option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option> */}
              </select>
              <select
                id="gender"
                value={filterPremium}
                onChange={handleChangePremium}
                className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 
                py-2 leading-tight text-gray-700 focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                <option value={""}>{t("assistantForm.all")}</option>
                <option value={"Premium"}>{t("assistantForm.premium")}</option>
                <option value={"Normal"}>{t("assistantForm.normal")}</option>
              </select>
            </div>
            <div className="h-full overflow-auto pb-8">
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
                {filteredVoices &&
                  filteredVoices.map((op: any, i: any) => (
                    <div
                      key={i}
                      className="flex cursor-pointer items-center justify-between border-[1px] p-3"
                      onClick={() => handleOptionSelect(op)}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={`https://precall.hopprz.com/flags/${op?.accent.toLowerCase()}.svg`}
                          alt="flag"
                          className="w-[40px]"
                        />
                        <span className="text-left">
                          <span className="flex items-center">
                            <span className="font-bold">{op?.displayname}</span>{" "}
                            <span>({op?.language})</span>
                            {op?.is_premium && (
                              <span>
                                <img
                                  src={premiumIcon}
                                  className="ml-3 h-[20px] w-[20px] rounded-lg"
                                  alt="primium"
                                />
                              </span>
                            )}
                          </span>
                          {/* <br /> */}
                          {op?.gender}{" "}
                        </span>
                      </div>
                      <div>
                        {isOptionSelected(op) && <FaCheck color="green" />}{" "}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter display={"flex"} justifyContent={"start"}>
            <Button
              onClick={handleChooseItem}
              colorScheme="blue"
              mr={3}
              isDisabled={!selectedOption}
            >
              {t("assistantForm.choose")}
            </Button>
            <Button onClick={handleCloseAccent}>{t("cancel")}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default GenderAccentModal;
