import Card from "components/card";
import { Input, InputGroup, InputLeftAddon, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { applyCoupon, paymentApi } from "services/pricing";
import Loader from "components/Loader/Loader";
import { useTranslation } from "react-i18next";

const AddAmount = ({ setAddAmount }: any) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [payAmount, setPayAmount] = useState<any>(0);
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required(t("billing.amountValid"))
      .min(15, t("billing.amountLess"))
      .max(10000, t("billing.amountMost")),
    coupon: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const paymentPayload = {
        amount: values?.amount,
        minimum_amount: 15,
        couponCode: couponData ? couponValue : "",
        extra_amount: Number(discountAmount.toFixed(2)),
      };
      setLoading(true);
      paymentApi(paymentPayload)
        .then((response) => {
          if (response.success) {
            setLoading(false);
            // window.open(response.redirectUrl);
            window.location.href = response.redirectUrl;
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    },
  });

  useEffect(() => {
    if (couponData) {
      if (
        Number(couponData?.maximum_amount) <
        Number(payAmount) / Number(couponData?.extra_percentage)
      ) {
        setDiscountAmount(Number(couponData?.maximum_amount));
      } else {
        setDiscountAmount(
          Number(payAmount) / Number(couponData?.extra_percentage)
        );
      }
    } else {
      setDiscountAmount(0);
    }
  }, [couponData, payAmount]);

  const handleCouponChange = (e: any) => {
    setCouponValue(e.target.value);
  };

  const handleApplyCoupon = () => {
    const couponPayload = {
      couponCode: couponValue,
    };
    setLoading(true);
    applyCoupon(couponPayload)
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          setCouponData(response?.data);
          toast({
            description: t("billing.couponAppliedSuccessfully"),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
        } else {
          setCouponData(null);
          toast({
            description: response?.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const amount: any = localStorage.getItem("amount");
  const currentBalance = amount !== "null" || amount !== "NaN" ? amount : 0;
  return (
    <div className="flex w-full flex-col gap-5">
      {loading && <Loader />}
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-12 lg:col-span-12 lg:!mb-0">
          <Card extra={"w-full h-full p-[24px] bg-cover"}>
            <div className="text-[22px] font-semibold hover:text-navy-700 dark:hover:text-white">
              {t("billing.howMuchWould")}
            </div>
            <div className="mt-12 flex flex-col justify-between xxl:flex-row">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-2">
                  <div className="mb-1 text-[20px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                    {t("billing.paymentAmount")}
                  </div>
                  <div className="text-[26px] font-bold capitalize">
                    <InputGroup>
                      <InputLeftAddon>$</InputLeftAddon>
                      <Input
                        type="number"
                        id="amount"
                        name="amount"
                        placeholder={t("billing.enterAmount")}
                        className="w-fit border-[1px]"
                        value={formik.values.amount}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setPayAmount(e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </InputGroup>
                  </div>
                  {formik.touched.amount && formik.errors.amount ? (
                    <div className="text-red-500">{formik.errors.amount}</div>
                  ) : null}
                  <div className="mt-2 text-[15px] font-bold text-gray-400 dark:hover:text-white">
                    {t("billing.amountBetween")}
                  </div>
                  <div className="mb-1 text-[20px] font-bold capitalize hover:text-navy-700 dark:hover:text-white">
                    {t("billing.applyCouponCode")}
                  </div>
                  <div className="flex gap-2 text-[26px] font-bold capitalize">
                    <input
                      className="inline-flex h-10 w-full items-center rounded border border-gray-500 bg-white px-4 py-2 text-sm font-medium focus:border-blue-500 focus:outline-none focus:ring-blue-700 dark:bg-navy-900"
                      id="coupon"
                      name="coupon"
                      placeholder={t("billing.enterCouponCode")}
                      type="text"
                      value={couponValue}
                      onChange={handleCouponChange}
                      disabled={
                        formik.errors.amount || !payAmount ? true : false
                      }
                    />
                    <button
                      type="button"
                      disabled={
                        formik.errors.amount || !couponValue ? true : false
                      }
                      onClick={handleApplyCoupon}
                      className={`linear h-10 w-fit whitespace-nowrap rounded  px-2 py-2 text-base font-medium text-white transition duration-200 ${
                        formik.errors.amount || !couponValue
                          ? "bg-gray-300"
                          : "bg-blue-700 hover:bg-blue-800 active:bg-blue-800"
                      }`}
                    >
                      {t("billing.apply")}
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row">
                    <button
                      type="button"
                      onClick={() => setAddAmount(false)}
                      className="linear text-black mt-2 w-full rounded border bg-[#edf2f7] px-2 py-[12px] text-base font-medium transition duration-200 hover:bg-[#dbdee1] dark:border-blue-700 dark:bg-navy-900 dark:text-blue-700 xxl:w-fit"
                    >
                      {t("cancel")}
                    </button>
                    <button
                      type="submit"
                      className="linear mt-2 w-full rounded bg-blue-700 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-800 active:bg-blue-800"
                    >
                      {t("add")} $
                      {formik.values.amount ? formik.values.amount : 0}{" "}
                      {t("billing.toMyBalance")}
                    </button>
                  </div>
                </div>
              </form>
              <div className="mt-4 flex w-full flex-col rounded border-[2px] border-[#E2E8F0] p-5 xxl:mt-0 xxl:max-w-[600px]">
                <div className="mb-1 text-[26px] font-extrabold capitalize hover:text-navy-700 dark:hover:text-white">
                  {t("billing.overview")}
                </div>
                <div className="my-3 flex justify-between text-[20px] font-extrabold capitalize hover:text-navy-700 dark:hover:text-white">
                  <span>{t("billing.currentBal")}:</span>
                  <span>${amount !== "null" ? amount : 0.0}</span>
                </div>
                <hr className="border-[2px]" />
                <div className="my-3 flex justify-between text-[20px] font-light capitalize hover:text-navy-700 dark:hover:text-white">
                  <span>{t("billing.paymentAmount")}:</span>
                  <span>
                    $
                    {formik.values.amount
                      ? Number(formik.values.amount).toFixed(2)
                      : 0}
                  </span>
                </div>
                <hr className="border-[2px]" />
                {couponData && (
                  <>
                    <div className="my-3 flex justify-between text-[20px] font-light capitalize hover:text-navy-700 dark:hover:text-white">
                      <span>{t("billing.couponAmount")}:</span>
                      <span>${discountAmount.toFixed(2)}</span>
                    </div>
                    <hr className="border-[2px]" />
                  </>
                )}
                <div className="my-3 flex justify-between text-[20px] font-extrabold capitalize hover:text-navy-700 dark:hover:text-white">
                  <span>{t("billing.newBalance")}:</span>
                  <span>
                    {" "}
                    $
                    {formik.values.amount
                      ? discountAmount
                        ? (
                            Number(formik.values.amount) +
                            discountAmount +
                            Number(currentBalance)
                          ).toFixed(2)
                        : (
                            Number(formik.values.amount) +
                            Number(currentBalance)
                          ).toFixed(2)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AddAmount;
