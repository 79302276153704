import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";
// import { get_token } from "utils/utils";

// SIGNUP API

export const signUp = async (signUpPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/addUser`,
      signUpPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// SIGNIN API

export const signIn = async (signInPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  try {
    const response = await axios.post(`${baseUrl}/user/login`, signInPayload);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//FORGOT PASSWORD API

export const forgotPassword = async (forgotPasswordPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/forgotPassword`,
      forgotPasswordPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//RESET PASSWORD API

export const resetPassword = async (resetPasswordPayload: any, code: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/resetPassword?code=${code}`,
      resetPasswordPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//ONBOARDING STEP1 LISTING API

export const step1InfoListing = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/user/info_listing`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//ONBOARDING STEP1 SUBMIT API

export const step1InfoSubmit = async (step1InfoSubmitPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/basic_information`,
      step1InfoSubmitPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//ONBOARDING STEP3 SUBMIT API

export const step3Submit = async (step3SubmitPayload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/createAssistant`,
      step3SubmitPayload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//PROFILE DETAIL API

export const profileDetail = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(`${baseUrl}/user/profile`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//PROFILE IMAGE UPDATE API

export const profileImageUpdate = async (formData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/uploadProfile`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//PROFILE DETAIL API

export const profileDetailUpdate = async (payload: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.put(
      `${baseUrl}/user/profile/update`,
      payload,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//VERIFY USER API

export const verifyUser = async (code: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.get(
      `${baseUrl}/user/verifyUser?code=${code}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

//CHANGE PASSWORD API

export const changePassword = async (request: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/user/changePassword`,
      request,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GOOGLE LOGIN API

export const googleLogin = async (request: any, affiliate_id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const config: AxiosRequestConfig = {
    headers: headers,
  };
  const updatedRequest = {
    ...request,
    affiliate_id: affiliate_id,
  };

  try {
    const response = await axios.post(
      `${baseUrl}/user/google-auth`,
      updatedRequest,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
