import Dialer from "views/admin/dialer";
import SignIn from "views/auth/SignIn";
import {
  MdLocalLibrary,
  MdOutlineCallSplit,
  MdOutlineDashboard,
  MdPeople,
  MdSegment,
} from "react-icons/md";
import { MdOutlineCall } from "react-icons/md";
import { MdPermContactCalendar } from "react-icons/md";
import { PiSpeakerSimpleHighBold } from "react-icons/pi";
import { MdOutlineSupportAgent } from "react-icons/md";
import { MdGroups } from "react-icons/md";
import { BiDollar } from "react-icons/bi";
import { MdHome, MdLock } from "react-icons/md";
// import { MdRecordVoiceOver } from "react-icons/md";
import SignUp from "views/auth/Signup";
import ResetPassword from "views/auth/ResetPassword";
import ForgotPassword from "views/auth/ForgotPassword";
import OnBoarding from "views/auth/OnBoarding";
import Campaigns from "views/admin/campaigns";
import Support from "views/admin/support";
import CampaignsDetailTable from "views/admin/campaignsDetail";
import VerifyUser from "views/auth/VerifyUser";
import Verify from "views/auth/Verify";
import Price from "views/admin/profile";
import PaymentSuccess from "views/admin/paymentSuccess";
import CallPlayGround from "views/admin/callPlayground/CallPlayGround";
import AssistanceList from "views/admin/assitstants/assistanceList";
import AssistantUpdate from "views/admin/assitstants/updateAssistance";
import AssistantCreate from "views/admin/assitstants/createAssistant";
// import InboundAssistanceList from "views/admin/inboundAssitstants";
import InboundAssistantIncomingCalls from "views/admin/inboundAssitstants/components/InboundAssistantIncomingCalls";
import KnowledgeBaseList from "views/admin/knowledgeBase/knowledgeBaseList";
import KnowledgeBaseCreate from "views/admin/knowledgeBase/createKnowledgeBase";
import KnowledgeBaseUpdate from "views/admin/knowledgeBase/updateKnowledgeBase";
import InfoExtractorTable from "views/admin/campaignsDetail/InfoExtractorTable";
import Affiliate from "views/admin/affiliate";
import AffiliatedUsers from "views/admin/affiliate/affiliatedUsers";
import Integrations from "views/admin/integrations";
import SMTPList from "views/admin/integrations/smtp";
import InviteUser from "views/admin/profile/inviteUser";
import Dashboard from "views/admin/dashboard";
// import { SiChatbot } from "react-icons/si";
// import { TbSettingsAutomation } from "react-icons/tb";
import { RiContactsBookLine } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";
import { FaRegBuilding } from "react-icons/fa";
// import Automation from "views/admin/automation";
import CallLogs from "views/admin/callLogs";
// import Chatbot from "views/admin/chatbot";
import Companies from "views/admin/contacts/companies";
import Segments from "views/admin/contacts/segments";
import Deals from "views/admin/contacts/deals";
// import Tickets from "views/admin/contacts/tickets";
import Contacts from "views/admin/contacts/contacts";
import ContactDetail from "views/admin/contacts/contacts/contactDetails";
import AudianceList from "views/admin/contacts/segments/audiances";
import WhatsAppComponent from "views/admin/integrations/whatsapp";
import { useTranslation } from "react-i18next";
const useRoutes = () => {
  const { t } = useTranslation();
  const routes = [
    {
      name: `${t("route.dashboard")}`,
      layout: "/admin",
      icon: <MdOutlineDashboard className="h-6 w-6" />,
      path: "dashboard",
      sidebar: true,
      component: <Dashboard />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.contacts")}`,
      layout: "/admin",
      path: "contacts",
      sidebar: true,
      icon: <RiContactsBookLine className="h-6 w-6" />,
      component: <Contacts />,
      submenu: true,
      submenuItems: [
        {
          name: `${t("route.contacts")}`,
          layout: "/admin",
          path: "contacts",
          sidebar: true,
          icon: <MdPermContactCalendar className="h-6 w-6" />,
          component: <Contacts />,
        },
        {
          name: `${t("route.companies")}`,
          layout: "/admin",
          path: "companies",
          sidebar: true,
          icon: <FaRegBuilding className="h-6 w-6" />,
          component: <Companies />,
        },
        {
          name: `${t("route.segments")}`,
          layout: "/admin",
          path: "segments",
          sidebar: true,
          icon: <MdSegment className="h-6 w-6" />,
          component: <Segments />,
          secondary: true,
        },
        {
          name: `${t("route.deals")}`,
          layout: "/admin",
          path: "deals",
          sidebar: true,
          icon: <FaHandshakeSimple className="h-6 w-6" />,
          component: <Deals />,
        },
        // {
        //   name: "Tickets",
        //   layout: "/admin",
        //   icon: <FaTicket className="h-6 w-6" />,
        //   path: "tickets",
        //   sidebar: true,
        //   component: <Tickets />,
        // },
      ],
    },
    {
      name: `${t("route.contactDetails")}`,
      layout: "/admin",
      path: "/contacts/contact-detail",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <ContactDetail />,
      submenu: false,
      submenuItems: [],
    },
    // {
    //   name: "Contact List",
    //   layout: "/admin",
    //   icon: <MdPermContactCalendar className="h-6 w-6" />,
    //   path: "contact-list",
    //   sidebar: true,
    //   component: <DataTables />,
    //   submenu: false,
    //   submenuItems: [],
    // },
    {
      name: `${t("route.callPlatforms")}`,
      layout: "/admin",
      path: "assistants",
      sidebar: true,
      icon: <MdLocalLibrary className="h-6 w-6" />,
      component: <AssistanceList />,
      submenu: true,
      submenuItems: [
        {
          name: `${t("route.assistants")}`,
          layout: "/admin",
          path: "assistants",
          sidebar: true,
          icon: <MdPeople className="h-6 w-6" />,
          component: <AssistanceList />,
        },
        {
          name: `${t("route.playground")}`,
          layout: "/admin",
          path: "call-playground",
          sidebar: true,
          icon: <MdGroups className="h-6 w-6" />,
          component: <CallPlayGround />,
        },
        {
          name: `${t("route.dialers")}`,
          layout: "/admin",
          path: "dialers",
          sidebar: true,
          icon: <MdOutlineCall className="h-6 w-6" />,
          component: <Dialer />,
          secondary: true,
        },
        {
          name: `${t("route.campaigns")}`,
          layout: "/admin",
          icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
          path: "campaigns",
          sidebar: true,
          component: <Campaigns />,
        },
      ],
    },
    // {
    //   name: "Automation",
    //   layout: "/admin",
    //   icon: <TbSettingsAutomation className="h-6 w-6" />,
    //   path: "automation",
    //   sidebar: true,
    //   component: <Automation />,
    //   submenu: false,
    //   submenuItems: [],
    // },
    {
      name: `${t("route.callLogs")}`,
      layout: "/admin",
      icon: <MdOutlineCallSplit className="h-6 w-6" />,
      path: "call-logs",
      sidebar: true,
      component: <CallLogs />,
      submenu: false,
      submenuItems: [],
    },
    // {
    //   name: "Chatbot",
    //   layout: "/admin",
    //   icon: <SiChatbot className="h-6 w-6" />,
    //   path: "chatbot",
    //   sidebar: true,
    //   component: <Chatbot />,
    //   submenu: false,
    //   submenuItems: [],
    // },
    {
      name: `${t("route.support")}`,
      layout: "/admin",
      icon: <MdOutlineSupportAgent className="h-6 w-6" />,
      path: "support",
      sidebar: true,
      component: <Support />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.billing")}`,
      layout: "/admin",
      path: "price",
      sidebar: false,
      icon: <BiDollar className="h-6 w-6" />,
      component: <Price />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "",
      layout: "/admin",
      path: "paymentsuccess",
      sidebar: false,
      icon: <BiDollar className="h-6 w-6" />,
      component: <PaymentSuccess />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.assistantSetting")}`,
      layout: "/admin",
      path: "/assistants/update-assistant",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <AssistantUpdate />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.createAssistant")}`,
      layout: "/admin",
      path: "/assistants/create-assistant",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <AssistantCreate />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.knowledgeBase")}`,
      layout: "/admin",
      path: "knowledge-base",
      sidebar: false,
      icon: <MdPeople className="h-6 w-6" />,
      component: <KnowledgeBaseList />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.affiliate")}`,
      layout: "/admin",
      path: "affiliate",
      sidebar: false,
      icon: <MdPeople className="h-6 w-6" />,
      component: <Affiliate />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.affiliatedUsers")}`,
      layout: "/admin",
      path: "/affiliate/affiliate-users",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <AffiliatedUsers />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.integrations")}`,
      layout: "/admin",
      path: "integrations",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <Integrations />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.updateKnowledgeBase")}`,
      layout: "/admin",
      path: "/knowledge-base/update-knowledge",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <KnowledgeBaseUpdate />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.createKnowledgeBase")}`,
      layout: "/admin",
      path: "/knowledge-base/create-knowledge",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <KnowledgeBaseCreate />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.SMTPIntegration")}`,
      layout: "/admin",
      path: "smtp-integration",
      sidebar: false,
      icon: <MdPeople className="h-6 w-6" />,
      component: <SMTPList />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.whatsappIntegration")}`,
      layout: "/admin",
      path: "whatsapp-integration",
      sidebar: false,
      icon: <MdPeople className="h-6 w-6" />,
      component: <WhatsAppComponent />,
      submenu: false,
      submenuItems: [],
    },

    {
      name: `${t("route.inboundAssistantIncomingCalls")}`,
      layout: "/admin",
      path: "/dialer/incoming-calls",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <InboundAssistantIncomingCalls />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.audiance")}`,
      layout: "/admin",
      path: "/segment/audiance",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <AudianceList />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.campaigns")}`,
      layout: "/admin",
      path: "campaigns-detail",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <CampaignsDetailTable />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.infoExtractor")}`,
      layout: "/admin",
      path: "campaigns-detail/info",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <InfoExtractorTable />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "Sign Up",
      layout: "/auth/sign-up",
      path: "/sign-up",
      sidebar: false,
      icon: <MdHome className="h-6 w-6" />,
      component: <SignUp />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "Forgot Password",
      layout: "/auth/forgot-password",
      path: "/forgot-password",
      sidebar: false,
      icon: <MdHome className="h-6 w-6" />,
      component: <ForgotPassword />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "Reset Password",
      layout: "/auth/reset-password",
      path: "/reset-password",
      sidebar: false,
      icon: <MdHome className="h-6 w-6" />,
      component: <ResetPassword />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "Sign In",
      layout: "/auth/sign-in",
      path: "/sign-in",
      sidebar: false,
      icon: <MdLock className="h-6 w-6" />,
      component: <SignIn />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "OnBoarding",
      layout: "/auth/onboarding",
      path: "/onboarding",
      sidebar: false,
      icon: <MdHome className="h-6 w-6" />,
      component: <OnBoarding />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "Verify User",
      layout: "/auth/verifyuser",
      path: "/verifyuser",
      sidebar: false,
      icon: <MdHome className="h-6 w-6" />,
      component: <VerifyUser />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: "Verify",
      layout: "/auth/verify",
      path: "/verify",
      sidebar: false,
      icon: <MdHome className="h-6 w-6" />,
      component: <Verify />,
      submenu: false,
      submenuItems: [],
    },
    {
      name: `${t("route.addUsers")}`,
      layout: "/admin",
      path: "invite-users",
      sidebar: false,
      icon: <PiSpeakerSimpleHighBold className="h-6 w-6" />,
      component: <InviteUser />,
      submenu: false,
      submenuItems: [],
    },
  ];
  return routes;
};
export default useRoutes;
