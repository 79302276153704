import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LuSearch, LuX } from "react-icons/lu";

const SearchBarInput = ({ initialData, handleFilteredData }: any) => {
  const [query, setQuery] = useState(initialData);
  const { t } = useTranslation();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    // handleFilteredData(value);
  };

  const handleClearSearch = () => {
    setQuery("");
    handleFilteredData("");
  };

  const handleSearch = () => {
    handleFilteredData(query);
  };

  return (
    <div className="w-[250px]">
      <div className="relative flex w-full items-center">
        <input
          type="text"
          id="default-search"
          className="bg-gray-50s block w-full rounded border border-gray-300 p-3 pr-16 text-sm text-gray-900 focus:outline-none dark:border-white dark:bg-[#111C44] dark:text-white"
          placeholder={t("search")}
          value={query}
          onChange={handleInputChange}
        />
        {query && (
          <button
            onClick={handleClearSearch}
            className="absolute right-8 flex items-center justify-center text-gray-400"
          >
            <LuX className="mr-2" size={20} />
          </button>
        )}
        <button
          onClick={handleSearch}
          className="absolute right-0 flex items-center justify-center rounded bg-blue-700 p-1 py-2 text-white hover:bg-blue-800"
        >
          <LuSearch size={25} />
        </button>
      </div>
    </div>
  );
};

export default SearchBarInput;
