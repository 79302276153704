/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@chakra-ui/react";
import SendMessageModal from "components/modal/AddSendMessageModal";
import TransferNumberModal from "components/modal/AddTransferNumberModal";
import EditSendEmailModal from "components/modal/EditSendEmailModal";
import EditTransferNumberModal from "components/modal/EditTransferNumberModal";
import AddSendEmailModal from "components/modal/AddSendEmailModal";
import ScriptModal from "components/modal/ScriptModal";
import TooltipHorizon from "components/tooltip";
import React, { useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { FaArrowLeft, FaCheck, FaSync } from "react-icons/fa";
import { FaRegMessage } from "react-icons/fa6";
import { GoQuestion } from "react-icons/go";
import { HiArrowsRightLeft } from "react-icons/hi2";
import { IoMdSettings } from "react-icons/io";
import { LuFileSearch2 } from "react-icons/lu";
import { MdDelete, MdEmail } from "react-icons/md";
import { Mention, MentionsInput } from "react-mentions";
import AddInfoExtractorModal from "components/modal/AddInfoExtractorModal";
import EditSendMessageModal from "components/modal/EditSendMessageModal";
import RealTimeBookingModal from "components/modal/RealTimeBookingModal";
import CRMsyncModal from "components/modal/CRMsyncModal";
import { useTranslation } from "react-i18next";

const StepThree = ({
  formik,
  handlePrevStep,
  knowledgeBaseList,
  infoExtractorData,
  transferCallData,
  sendMessageData,
  sendEmailData,
  bookingSlotData,
  zapierData,
  syncData,
  handleRemoveAction,
  setZapierData,
  actionData,
  setActionData,
  questionsData,
  setBookingData,
  bookingData,
  currentDate,
  isOpenBooking,
  setIsOpenBooking,
}: any) => {
  const { t } = useTranslation();
  const [isOpenTransferNumber, setIsOpenTransferNumber] = useState(false);
  const [isOpenopenSendMessage, setIsOpenopenSendMessage] = useState(false);
  const [isOpenEditTransferNumber, setIsOpenEditTransferNumber] =
    useState(false);
  const [isOpenEditSendMessage, setIsOpenEditSendMessage] = useState(false);
  const [isOpenExtractor, setIsOpenExtractor] = useState(false);
  const [isOpenSync, setIsOpenSync] = useState(false);
  const [isOpenSendEmail, setIsOpenSendEmail] = useState(false);
  const [isOpenEditSendEmail, setIsOpenEditSendEmail] = useState(false);
  const usersData = [
    {
      id: "firstName",
      display: "FirstName",
    },
    {
      id: "lastName",
      display: "LastName",
    },
  ];
  const handlePrev = () => {
    handlePrevStep();
  };
  const openBookingModal = () => {
    setIsOpenBooking(true);
  };
  const handleCloseBookingModal = () => {
    setIsOpenBooking(false);
  };
  const openExtractorModal = () => {
    setIsOpenExtractor(true);
  };
  const handleCloseExtractorModal = () => {
    setIsOpenExtractor(false);
  };
  const openEditSendMessageModal = () => {
    setIsOpenEditSendMessage(true);
  };
  const handleCloseEditSendMessageModal = () => {
    setIsOpenEditSendMessage(false);
  };
  const openSendMessageModal = () => {
    setIsOpenopenSendMessage(true);
  };
  const handleCloseSendMessageModal = () => {
    setIsOpenopenSendMessage(false);
  };
  const openSendEmailModal = () => {
    setIsOpenSendEmail(true);
  };
  const handleCloseSendEmailModal = () => {
    setIsOpenSendEmail(false);
  };
  const openEditSendEmailModal = () => {
    setIsOpenEditSendEmail(true);
  };
  const handleCloseEditSendEmailModal = () => {
    setIsOpenEditSendEmail(false);
  };
  const openTransferNumberModal = () => {
    setIsOpenTransferNumber(true);
  };
  const handleCloseTransferNumberModal = () => {
    setIsOpenTransferNumber(false);
  };
  const openEditTransferNumberModal = () => {
    setIsOpenEditTransferNumber(true);
  };
  const handleCloseEditTransferNumberModal = () => {
    setIsOpenEditTransferNumber(false);
  };
  const openSyncModal = () => {
    setIsOpenSync(true);
  };
  const handleCloseSyncModal = () => {
    setIsOpenSync(false);
  };
  return (
    <div>
      <div className="mt-3 flex flex-col gap-6 md:flex-row">
        <div className="flex w-full flex-col gap-3 md:w-[70%]">
          <div className="relative flex w-full flex-col gap-1">
            <label className="  dark:text-white" htmlFor="companyName">
              {t("assistantForm.callOpeningText")} *
            </label>
            <MentionsInput
              placeholder={t("assistantForm.callOpeningPlaceholder1")}
              value={formik?.values.start_speech}
              onChange={(e) =>
                formik.setFieldValue("start_speech", e.target.value)
              }
              className="mentions-input min-h-[50px] rounded border border-gray-500 bg-white p-2 focus:border-blue-400 focus:outline-none focus:ring-blue-700 dark:bg-navy-900 dark:hover:border-[#fff]"
              a11ySuggestionsListLabel={"Suggested mentions"}
              style={{
                control: {
                  fontSize: 16,
                  fontWeight: 500,
                },
                "&multiLine": {
                  control: {
                    minHeight: 32,
                    maxHeight: 32,
                  },
                  input: {
                    padding: 9,
                  },
                },
                suggestions: {
                  list: {
                    backgroundColor: "white",
                    border: "1px solid rgba(0,0,0,0.15)",
                    fontSize: 14,
                  },
                  item: {
                    padding: "5px 15px",
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    "&focused": {
                      backgroundColor: "#cee4e5",
                    },
                  },
                },
              }}
            >
              <Mention
                trigger="@"
                data={usersData}
                appendSpaceOnAdd={true}
                className="mentions__mention"
              />
            </MentionsInput>
            {formik.touched.start_speech && formik.errors.start_speech ? (
              <div className="text-red-500">
                {formik.errors.start_speech.toString()}
              </div>
            ) : null}
          </div>
          <div className="flex w-full flex-col gap-1">
            <ScriptModal
              value={formik.values.script}
              allValues={formik.values}
              status="assistant"
              handleChange={(value: any) => {
                formik.setFieldValue("script", value);
              }}
              id={"script"}
              disabled={false}
              quillCss={
                "max-h-[215px] min-h-[215px] md:min-h-[671px] md:max-h-[671px] lg:max-h-[625px] lg:min-h-[625px] xl:max-h-[594px] xl:min-h-[594px] 2xl:max-h-[596px] 2xl:min-h-[596px]"
              }
            />
            {formik.touched.script && formik.errors.script ? (
              <div className="text-red-500">
                {formik.errors.script.toString()}
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex w-full flex-col gap-3 md:w-[30%]">
          <div className="flex flex-col gap-1 rounded-lg">
            <label className="  dark:text-white" htmlFor="Prompt">
              {t("assistantForm.knowledgeBase")}
            </label>
            <div className="flex items-center gap-2">
              <select
                id="knowledge_base_Id"
                name="knowledge_base_Id"
                value={formik.values.knowledge_base_Id}
                onChange={formik.handleChange}
                className="h-[50px] w-full rounded border-[1px] border-gray-500 pl-4 text-sm leading-tight focus:border-blue-700 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              >
                <option value="">
                  {t("assistantForm.selectKnowledgeBase")}
                </option>
                {knowledgeBaseList?.map((item: any, index: number) => (
                  <option key={index} value={item?.hash_id}>
                    {item?.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="relative mt-3 flex w-full flex-col gap-1">
            <div className="flex flex-col items-center justify-between gap-2">
              <button
                className={`relative flex h-full w-full cursor-pointer flex-col items-start justify-center gap-2 rounded border border-gray-500 p-3 text-center text-[16px] font-semibold text-gray-800 hover:border-blue-700`}
                onClick={() => {
                  openExtractorModal();
                }}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <LuFileSearch2 className="h-5 w-5 text-blue-700" />{" "}
                    <span className="rounded-full bg-gray-200 px-2 py-1 text-[12px] text-gray-700">
                      {t("assistantForm.liveInteraction")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {infoExtractorData?.questions?.length && (
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAction("infoExtractor");
                        }}
                        className={`h-6 w-6 cursor-pointer`}
                        color="red"
                      />
                    )}
                    <IoMdSettings
                      onClick={() => {
                        openExtractorModal();
                      }}
                      className={`h-6 w-6 cursor-pointer dark:text-white`}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center gap-2 dark:text-white">
                  {" "}
                  {t("assistantForm.infoExtractor")}{" "}
                  <TooltipHorizon
                    trigger={
                      <span>
                        <GoQuestion className="h-5 w-5 text-gray-700" />
                      </span>
                    }
                    placement="top"
                    content={t("assistantForm.infoExtractorTooltip")}
                    extra="rounded text-[11px]"
                  />
                </div>
                {infoExtractorData?.questions?.length && (
                  <FaCheck
                    className="absolute bottom-4 right-4 h-4 w-4"
                    color="#28df20"
                  />
                )}
              </button>
              <button
                className={`relative flex h-full w-full cursor-pointer flex-col items-start justify-center gap-2 rounded border border-gray-500 p-3 text-center text-[16px] font-semibold text-gray-800 hover:border-blue-700`}
                onClick={() => {
                  if (transferCallData?.number) {
                    openEditTransferNumberModal();
                  } else {
                    openTransferNumberModal();
                  }
                }}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <HiArrowsRightLeft className="h-5 w-5  text-blue-700" />{" "}
                    <span className="rounded-full bg-gray-200 px-2 py-1 text-[12px] text-gray-700">
                      {t("assistantForm.InCallText")}
                    </span>
                  </div>
                  <span className="flex items-center gap-2">
                    {transferCallData?.number && (
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAction("transferCall");
                        }}
                        className={`h-6 w-6 cursor-pointer`}
                        color="red"
                      />
                    )}
                    <IoMdSettings
                      onClick={() => {
                        if (transferCallData?.number) {
                          openEditTransferNumberModal();
                        } else {
                          openTransferNumberModal();
                        }
                      }}
                      className={`h-6 w-6 cursor-pointer dark:text-white`}
                    />
                  </span>
                </div>
                <div className="mt-3 flex items-center gap-2 dark:text-white">
                  {" "}
                  {t("assistantForm.callTransferText")}{" "}
                  <TooltipHorizon
                    trigger={
                      <span>
                        <GoQuestion className="h-5 w-5 text-gray-700" />
                      </span>
                    }
                    placement="top"
                    content={t("assistantForm.callTransferTooltipText")}
                    extra="rounded text-[11px]"
                  />
                </div>
                {transferCallData?.number && (
                  <FaCheck
                    className="absolute bottom-4 right-4 h-4 w-4"
                    color="#28df20"
                  />
                )}
              </button>
            </div>
          </div>
          <div className="relative flex w-full flex-col gap-1">
            <div className="flex flex-col items-center justify-between gap-2">
              <button
                className={`relative flex h-full w-full cursor-pointer flex-col items-start justify-center gap-2 rounded border border-gray-500 p-3 text-center text-[16px] font-semibold text-gray-800 hover:border-blue-700`}
                onClick={() => {
                  if (sendMessageData?.type) {
                    openEditSendMessageModal();
                  } else {
                    openSendMessageModal();
                  }
                }}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <FaRegMessage className="h-5 w-5  text-blue-700" />{" "}
                    <span className="rounded-full bg-gray-200 px-2 py-1 text-[12px] text-gray-700">
                      {t("assistantForm.smsText")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {sendMessageData?.type && (
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAction("sendMessage");
                        }}
                        className={`h-6 w-6 cursor-pointer`}
                        color="red"
                      />
                    )}
                    <IoMdSettings
                      onClick={() => {
                        if (sendMessageData?.type) {
                          openEditSendMessageModal();
                        } else {
                          openSendMessageModal();
                        }
                      }}
                      className={`h-6 w-6 cursor-pointer dark:text-white`}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center gap-2 dark:text-white">
                  {" "}
                  {t("assistantForm.sendSmsText")}{" "}
                  <TooltipHorizon
                    trigger={
                      <span>
                        <GoQuestion className="h-5 w-5 text-gray-700" />
                      </span>
                    }
                    placement="top"
                    content={t("assistantForm.sendSmsTooltipText")}
                    extra="rounded text-[11px]"
                  />
                </div>
                {sendMessageData?.type && (
                  <FaCheck
                    className="absolute bottom-4 right-4 h-4 w-4"
                    color="#28df20"
                  />
                )}
              </button>

              <button
                className={`relative flex h-full w-full cursor-pointer flex-col items-start justify-center gap-2 rounded border border-gray-500 p-3 text-center text-[16px] font-semibold text-gray-800 hover:border-blue-700`}
                onClick={() => {
                  if (sendEmailData?.description) {
                    openEditSendEmailModal();
                  } else {
                    openSendEmailModal();
                  }
                }}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <MdEmail className="h-5 w-5 text-blue-700" />{" "}
                    <span className="rounded-full bg-gray-200 px-2 py-1 text-[12px] text-gray-700">
                      {t("assistantForm.SMTP")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {sendEmailData?.description && (
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAction("sendMail");
                        }}
                        className={`h-6 w-6 cursor-pointer`}
                        color="red"
                      />
                    )}
                    <IoMdSettings
                      onClick={() => {
                        if (sendEmailData?.description) {
                          openEditSendMessageModal();
                        } else {
                          openSendMessageModal();
                        }
                      }}
                      className={`h-6 w-6 cursor-pointer dark:text-white`}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center gap-2 dark:text-white">
                  {" "}
                  {t("assistantForm.sendEmail")}
                  <TooltipHorizon
                    trigger={
                      <span>
                        <GoQuestion className="h-5 w-5 text-gray-700" />
                      </span>
                    }
                    placement="top"
                    content={
                      <ul className="numbered-list">
                        <li>{t("assistantForm.sendEmailTooltip1")}</li>
                        <li>{t("assistantForm.sendEmailTooltip2")}</li>
                      </ul>
                    }
                    extra="rounded text-[11px]"
                  />
                </div>
                {sendEmailData?.description && (
                  <FaCheck
                    className="absolute bottom-4 right-4 h-4 w-4"
                    color="#28df20"
                  />
                )}
              </button>
            </div>
          </div>
          <div className="relative flex w-full flex-col gap-1">
            <div className="flex flex-col items-center justify-between gap-2">
              <button
                className={`relative flex h-full w-full cursor-pointer flex-col items-start justify-center gap-2 rounded border border-gray-500 p-3 text-center text-[16px] font-semibold text-gray-800 hover:border-blue-700`}
                onClick={() => {
                  openBookingModal();
                }}
              >
                <div className="flex w-full items-center justify-between gap-1">
                  <div className="flex items-center gap-2">
                    <CiCalendar className="h-5 w-5  text-blue-700" />{" "}
                    <span className="rounded-full bg-gray-200 px-2 py-1 text-left text-[12px] text-gray-700">
                      {t("assistantForm.realTimeBooking2")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {bookingSlotData && (
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAction("scheduleMeeting");
                        }}
                        className={`h-6 w-6 cursor-pointer`}
                        color="red"
                      />
                    )}
                    <IoMdSettings
                      onClick={() => {
                        openBookingModal();
                      }}
                      className={`h-6 w-6 cursor-pointer dark:text-white`}
                    />
                  </div>
                </div>
                <div className="mt-3 flex items-center justify-start gap-2 text-left dark:text-white">
                  {" "}
                  {t("assistantForm.realTimeBooking")}
                  <TooltipHorizon
                    trigger={
                      <span>
                        <GoQuestion className="h-5 w-5 text-gray-700" />
                      </span>
                    }
                    placement="top"
                    content={t("assistantForm.realTimeBookingTooltip")}
                    extra="rounded text-[11px]"
                  />
                </div>
                {bookingSlotData && (
                  <FaCheck
                    className="absolute bottom-4 right-4 h-4 w-4"
                    color="#28df20"
                  />
                )}
              </button>
              <button
                className={`relative flex h-full w-full cursor-pointer flex-col items-start justify-center gap-2 rounded border border-gray-500 p-3 text-center text-[16px] font-semibold text-gray-800 hover:border-blue-700`}
                onClick={() => {
                  openSyncModal();
                }}
              >
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center gap-2">
                    <FaSync className="h-5 w-5  text-blue-700" />{" "}
                    <span className="rounded-full bg-gray-200 px-2 py-1 text-[12px] text-gray-700">
                      {t("assistantForm.SyncData")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    {(zapierData || syncData) && (
                      <MdDelete
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAction("crm");
                          setZapierData("");
                        }}
                        className={`h-6 w-6 cursor-pointer`}
                        color="red"
                      />
                    )}
                    <IoMdSettings
                      onClick={() => {
                        openSyncModal();
                      }}
                      className={`h-6 w-6 cursor-pointer dark:text-white`}
                    />
                  </div>
                </div>
                <div className="mt-3 flex gap-2 dark:text-white">
                  {" "}
                  {t("assistantForm.SyncDataCRM")}
                  <TooltipHorizon
                    trigger={
                      <span>
                        <GoQuestion className="h-5 w-5 text-gray-700" />
                      </span>
                    }
                    placement="top"
                    content={
                      <ul className="numbered-list">
                        <li>{t("assistantForm.SyncDataCRMTooltip1")}</li>
                        <li>{t("assistantForm.SyncDataCRMTooltip2")}</li>
                        <li>{t("assistantForm.SyncDataCRMTooltip3")}</li>
                      </ul>
                    }
                    extra="rounded text-[11px]"
                  />
                </div>
                {(zapierData || syncData) && (
                  <FaCheck
                    className="absolute bottom-4 right-4 h-4 w-4"
                    color="#28df20"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex justify-center gap-[20px]">
        <Button onClick={() => handlePrev()} gap={3}>
          <FaArrowLeft /> {t("assistantForm.backStep")}
        </Button>
        <Button
          onClick={() => formik.handleSubmit()}
          //   disabled={
          //     formik.values.type &&
          //     formik.values.company_name &&
          //     formik.values.name &&
          //     formik.values.language &&
          //     formik.values.goal
          //   }
          colorScheme={"blue"}
          className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          type="submit"
        >
          {t("submitText")}
        </Button>
      </div>
      <TransferNumberModal
        handleClose={handleCloseTransferNumberModal}
        isOpen={isOpenTransferNumber}
        setActionData={setActionData}
      />
      <EditTransferNumberModal
        handleClose={handleCloseEditTransferNumberModal}
        isOpen={isOpenEditTransferNumber}
        setActionData={setActionData}
        transferCallData={transferCallData}
        actionData={actionData}
      />
      <SendMessageModal
        handleClose={handleCloseSendMessageModal}
        isOpen={isOpenopenSendMessage}
        setActionData={setActionData}
      />
      <AddSendEmailModal
        handleClose={handleCloseSendEmailModal}
        isOpen={isOpenSendEmail}
        setActionData={setActionData}
      />
      <EditSendEmailModal
        handleClose={handleCloseEditSendEmailModal}
        isOpen={isOpenEditSendEmail}
        setActionData={setActionData}
        sendEmailData={sendEmailData}
        actionData={actionData}
      />
      <AddInfoExtractorModal
        handleClose={handleCloseExtractorModal}
        isOpen={isOpenExtractor}
        setActionData={setActionData}
        questionsData={questionsData}
        actionData={actionData}
      />
      <EditSendMessageModal
        handleClose={handleCloseEditSendMessageModal}
        isOpen={isOpenEditSendMessage}
        setActionData={setActionData}
        sendMessageData={sendMessageData}
        actionData={actionData}
      />
      <RealTimeBookingModal
        handleClose={handleCloseBookingModal}
        isOpen={isOpenBooking}
        setBookingData={setBookingData}
        bookingData={bookingData}
        minDate={currentDate}
        setActionData={setActionData}
      />
      <CRMsyncModal
        handleClose={handleCloseSyncModal}
        isOpen={isOpenSync}
        setActionData={setActionData}
        actionData={actionData}
        syncData={syncData}
        setZapierData={setZapierData}
        zapierData={zapierData}
      />
    </div>
  );
};

export default StepThree;
