import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { Avatar, AvatarBadge, FormLabel, useToast } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import {
  profileDetail,
  profileDetailUpdate,
  profileImageUpdate,
  step1InfoListing,
} from "services/Auth";
import Loader from "components/Loader/Loader";
import precallLogo from "../../assets/img/precallAi.png";
import { useTranslation } from "react-i18next";
const EditProfileModal = ({ onClose, isOpen }: any) => {
  const fileInputRef = useRef(null);
  const toast = useToast();
  const { t } = useTranslation();
  const [infoListData, setInfoListData] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [isProfileChange, setIsProfileChange] = useState(false);
  const handleAvatarEditClick = () => {
    fileInputRef.current.click();
  };

  const initialValues = {
    userProfile: userDetails?.profile_pic ? userDetails?.profile_pic : "",
    lastName: userDetails?.last_name ? userDetails?.last_name : "",
    firstName: userDetails?.first_name ? userDetails?.first_name : "",
    email: userDetails?.email ? userDetails?.email : "",
    industry_id: userDetails?.industry_id ? userDetails?.industry_id : null,
    roles_id: userDetails?.roles_id ? userDetails?.roles_id : null,
    objective_id: userDetails?.objective_id ? userDetails?.objective_id : null,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("profile.firstNameValid")),
    lastName: Yup.string().required(t("profile.lastNameValid")),
  });

  useEffect(() => {
    step1InfoListing()
      .then((response) => {
        setInfoListData(response.data);
      })
      .catch((error) => {
        console.error("Error :", error);
      });
  }, []);

  const handleImageChange = async (event: any) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    profileImageUpdate(formData)
      .then((response) => {
        if (response?.success) {
          setIsProfileChange(!isProfileChange);
          toast({
            description: t("messages.profileImageUpdated"),
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        console.error("Error Profile update:", error);
      });
  };

  useEffect(() => {
    profileDetail()
      .then((response) => {
        if (response?.success) {
          setUserDetails(response?.data[0]);
          localStorage?.setItem("amount", response?.data[0]?.amount);
          localStorage?.setItem("phone", response?.data[0]?.phone);
          localStorage?.setItem("profileImage", response?.data[0]?.profile_pic);
          localStorage?.setItem(
            "affiliation_amount",
            response?.data[0]?.affiliation_amount
          );
          localStorage?.setItem("user_id", response?.data[0]?.id);
          localStorage?.setItem(
            "affiliate_percentage",
            response?.affiliate_per
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  }, [isOpen, isProfileChange]);

  const handleProfileUpdate = (value: any) => {
    setLoading(true);
    const payload = {
      first_name: value?.firstName,
      last_name: value?.lastName,
      industry_id: value?.industry_id ? value?.industry_id : null,
      roles_id: value?.roles_id ? value?.roles_id : null,
      objective_id: value?.objective_id ? value?.objective_id : null,
      phone: userDetails?.phone ? userDetails?.phone : "",
    };
    profileDetailUpdate(payload)
      .then((response) => {
        if (response?.success) {
          toast({
            description: t("messages.profileUpdated"),
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
          onClose();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error submitting feedback:", error);
      });
  };
  let profileImage = localStorage.getItem("profileImage");
  return (
    <div>
      {loading && <Loader />}
      <Drawer
        placement="right"
        onClose={onClose}
        size={"md"}
        isOpen={isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent borderRadius={8} className="dark:bg-navy-900">
          <DrawerBody textAlign={"center"} padding={"20px 24px"}>
            <Avatar
              size="xl"
              name="User Profile"
              src={profileImage || precallLogo}
            >
              <AvatarBadge
                boxSize="1.25em"
                className="bg-blue-700"
                border="4px solid white"
                width="35px"
                height="35px"
                padding={"4px"}
                right={"2"}
                bottom={"2"}
                cursor={"pointer"}
                onClick={handleAvatarEditClick}
              >
                <MdEdit />
              </AvatarBadge>
            </Avatar>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values: any) => {
                handleProfileUpdate(values);
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    name="userProfile"
                    id="userProfile"
                    onChange={handleImageChange}
                  />
                  <div className="mt-6 flex gap-[16px] text-start">
                    <InputField
                      variant="auth"
                      extra="mb-3 w-full"
                      label={`${t("profile.firstName")} *`}
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder=""
                    />
                    <InputField
                      variant="auth"
                      extra="mb-3 w-full"
                      label={`${t("profile.lastName")} *`}
                      placeholder=""
                      id="lastName"
                      type="text"
                      name="lastName"
                    />
                  </div>
                  <InputField
                    variant="auth"
                    extra="mb-3 w-full text-start"
                    label={`${t("profile.email")} *`}
                    placeholder=""
                    id="email"
                    type="email"
                    name="email"
                    disabled
                  />
                  <FormLabel>{t("profile.whoRYou")} *</FormLabel>

                  <select
                    id="roles_id"
                    name="roles_id"
                    value={values.roles_id}
                    onChange={handleChange}
                    className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  >
                    {infoListData?.roles?.map((role: any, index: number) => (
                      <option key={index} value={role.hash_id}>
                        {role.role}
                      </option>
                    ))}
                  </select>

                  <FormLabel className="mt-2">
                    {t("profile.whatIndustry")} *
                  </FormLabel>

                  <select
                    id="objective_id"
                    name="objective_id"
                    value={values.objective_id}
                    onChange={handleChange}
                    className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  >
                    {infoListData?.industries?.map(
                      (industry: any, index: number) => (
                        <option key={index} value={industry.hash_id}>
                          {industry.industry_name}
                        </option>
                      )
                    )}
                  </select>
                  <FormLabel className="mt-2">
                    {t("profile.whatobjectives")} *
                  </FormLabel>

                  <select
                    id="industry_id"
                    name="industry_id"
                    value={values.industry_id}
                    onChange={handleChange}
                    className="w-full rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                  >
                    {infoListData?.objectives?.map(
                      (objective: any, index: number) => (
                        <option key={index} value={objective.hash_id}>
                          {objective.objective}
                        </option>
                      )
                    )}
                  </select>
                  <div className="mt-10 flex items-center justify-start gap-4">
                    <button
                      type="button"
                      onClick={onClose}
                      className="linear text-black rounded border bg-[#edf2f7] px-[16px] py-[6px] text-base font-medium transition duration-200 hover:bg-[#dbdee1] dark:border-blue-700 dark:bg-navy-900 dark:text-blue-700"
                    >
                      {t("cancel")}
                    </button>
                    <button
                      type="submit"
                      className="linear flex items-center justify-center gap-2 rounded bg-blue-700 px-[16px] py-[6px] text-base font-medium text-white transition duration-200 hover:bg-blue-800  active:bg-blue-800 dark:bg-blue-700 "
                    >
                      {t("save")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default EditProfileModal;
