import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
} from "@chakra-ui/react";
import { IoPerson } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import CallDurationTotal from "components/callDurationTotal";
import CallTranscribeModal from "./CallTranscribeModal";
import TooltipHorizon from "components/tooltip";
import WavePlayer from "components/waveSurferPlayer/WaveSurferPlayer";
import { useTranslation } from "react-i18next";

const CallLogDetailModal = ({
  isOpen,
  handleClose,
  rowData,
  campaignData,
  isStart,
  timeStatus,
}: any) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const audioUrl = process.env.REACT_APP_URL_FOR_AUDIO;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState<number | null>(
    null
  );
  const openTranscribeModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseTranscribe = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        size="xxl"
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"}>
          <ModalHeader>
            <div className="">
              <div className="flex items-center gap-2">
                <IoPerson />
                <p>{rowData?.full_name}</p>
              </div>
              <div className="flex items-center justify-between gap-2">
                <p className="flex items-center gap-2">
                  <IoMdCall /> {rowData?.phone}
                </p>
                {rowData?.calltranscribe &&
                  rowData?.answered_by !== "robot" &&
                  rowData?.newStatus !== "Failed" && (
                    <button
                      onClick={() => openTranscribeModal()}
                      className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
                    >
                      {t("playground.callTranscribe")}
                    </button>
                  )}
              </div>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <div className="w-full border-b border-gray-500"></div>
          <ModalBody pb={6}>
            <div className="w-full">
              <div className="mt-4 flex justify-between">
                <div className="flex flex-col items-center gap-1">
                  <p className="text-xl font-medium ">
                    {t("playground.status")}
                  </p>
                  <p className="text-lg ">
                    <span>
                      {rowData?.answered_by !== "robot" &&
                        rowData?.callsid &&
                        rowData?.status === "true" &&
                        rowData?.error === null &&
                        Number(rowData?.call_duration_in_sec) > 0 && (
                          <Tag variant="solid" colorScheme="green">
                            {t("campaign.completed")}
                          </Tag>
                        )}

                      {rowData?.answered_by !== "robot" &&
                        rowData?.callsid &&
                        rowData?.status === "false" &&
                        Number(rowData?.call_duration_in_sec) === 0 && (
                          <span>
                            {timeStatus(
                              rowData?.created_at,
                              Number(rowData?.maximum_time_per_call)
                            ) ? (
                              <Tag variant="solid" colorScheme="blue">
                                {t("campaign.inProgress")}
                              </Tag>
                            ) : (
                              <Tag variant="solid" colorScheme="yellow">
                                {t("campaign.failed")}
                              </Tag>
                            )}
                          </span>
                        )}
                      {rowData?.answered_by !== "robot" &&
                        rowData?.callsid &&
                        rowData?.status === "false" &&
                        Number(rowData?.call_duration_in_sec) > 0 && (
                          <span>
                            <Tag variant="solid" colorScheme="yellow">
                              {t("campaign.failed")}
                            </Tag>
                          </span>
                        )}
                      {rowData?.answered_by !== "robot" &&
                        !rowData?.callsid &&
                        rowData?.status === "false" && (
                          <span>
                            <Tag variant="solid" colorScheme="yellow">
                              {t("campaign.pending")}
                            </Tag>
                          </span>
                        )}
                      {rowData?.answered_by !== "robot" &&
                        rowData?.error !== null && (
                          <TooltipHorizon
                            trigger={
                              <Tag variant="solid" colorScheme="red">
                                {t("campaign.error")}
                              </Tag>
                            }
                            placement="bottom"
                            content={rowData?.error}
                            extra="rounded text-[11px]"
                          />
                        )}
                      {rowData?.answered_by === "robot" && (
                        <span>
                          {!rowData?.is_voice_note ? (
                            <Tag variant="solid" colorScheme="yellow">
                              {t("campaign.failed")}
                            </Tag>
                          ) : (
                            <Tag variant="solid">
                              {t("campaign.voiceNoteSent")}
                            </Tag>
                          )}
                        </span>
                      )}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="text-xl font-medium ">
                    {t("campaign.sentiments")}
                  </p>
                  <p className=" text-lg ">
                    {rowData?.call_sentiment &&
                    rowData?.answered_by !== "robot" &&
                    rowData?.newStatus !== "Failed" ? (
                      <span>
                        {rowData?.call_sentiment?.includes("positive") ? (
                          <Tag variant="solid" colorScheme="green">
                            {t("campaign.positive")}
                          </Tag>
                        ) : rowData?.call_sentiment?.includes("negative") ? (
                          <Tag variant="solid" colorScheme="red">
                            {t("campaign.negative")}
                          </Tag>
                        ) : (
                          <Tag variant="solid" colorScheme="blue">
                            {t("campaign.neutral")}
                          </Tag>
                        )}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <p className="text-xl font-medium ">
                    {t("campaign.duration")}
                  </p>
                  <p className=" text-lg ">
                    <Tag variant="solid" colorScheme="blue">
                      {rowData?.answered_by !== "robot" &&
                      rowData?.newStatus !== "Failed" ? (
                        <CallDurationTotal
                          totalDurationInSeconds={Number(
                            rowData?.call_duration_in_sec
                              ? rowData?.call_duration_in_sec
                              : 0
                          )}
                        />
                      ) : (
                        <CallDurationTotal totalDurationInSeconds={Number(0)} />
                      )}
                    </Tag>
                  </p>
                </div>
              </div>
              {rowData?.recording_url &&
                rowData?.answered_by !== "robot" &&
                rowData?.newStatus !== "Failed" && (
                  <div className="mt-8 w-full pt-8">
                    <WavePlayer
                      audio={
                        rowData?.recording_url?.startsWith("http://") ||
                        rowData?.recording_url?.startsWith("https://")
                          ? rowData?.recording_url
                          : audioUrl + rowData?.recording_url
                      }
                      index={0}
                      currentPlayingIndex={currentPlayingIndex}
                      setCurrentPlayingIndex={setCurrentPlayingIndex}
                    />
                  </div>
                )}
            </div>
            <CallTranscribeModal
              isOpen={isModalOpen}
              handleClose={handleCloseTranscribe}
              rowData={rowData?.calltranscribe}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CallLogDetailModal;
