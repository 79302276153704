import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// GET getKnowledgeBaseList
export const getKnowledgeBaseList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(`${baseUrl}/knowledgeBase/list`, config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// create KnowledgeBase
export const createKnowledgeBase = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/knowledgeBase/create`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// delete KnowledgeBase
export const deleteKnowledgeBase = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.delete(
      `${baseUrl}/knowledgeBase/delete?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// update KnowledgeBase
export const updateKnowledgeBase = async (data: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.put(
      `${baseUrl}/knowledgeBase/update?id=${id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// update KnowledgeBase
export const uploadKnowledgeBase = async (files: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const formData = new FormData();
  formData.append("file", files[0]);
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
  };
  const config = {
    headers: headers,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/knowledgeBase/generate-faqs`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
