import React, { useEffect, useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { FaArrowLeft, FaPhoneAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { updateAssistant } from "services/assitant";
import { useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { getCampaignVoiceList } from "services/campaign";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import AssistantModal from "../assistanceList/components/AssitantModal";
import { getKnowledgeBaseList } from "services/knowledgeBase";
import { MdSettingsVoice } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";
import { usePrompt } from "hooks/usePrompt";
import StepThree from "../createAssistant/stepThree";
import StepTwo from "../createAssistant/stepTwo";
import StepOne from "../createAssistant/stepOne";
import { AiFillRobot } from "react-icons/ai";
import Card from "components/card";
import { useTranslation } from "react-i18next";

interface FormValues {
  type: string;
  name: string;
  language: string;
  goal: string;
  company_name: string;
  script: string;
  gender: string;
  voice_name: string;
  start_speech: string;
  voice_id: string;
  transfer_number: string;
  meeting_link: string;
  voice_note: string;
  maximum_time_per_call: string;
  isTransfer: boolean;
  action: boolean;
  knowledge_base_Id: string;
  recording: boolean;
  is_voice_note: boolean;
  is_back_sound: string;
  voice_speed: number;
  is_filler: boolean;
  fillers: string[];
}
let allInitialValues: FormValues = {
  type: "outbound",
  name: "",
  language: "en",
  goal: "",
  company_name: "",
  script: "",
  gender: "",
  voice_name: "",
  start_speech: "Hello @[FirstName](firstName) , ",
  voice_id: "",
  transfer_number: "",
  meeting_link: "",
  voice_note: "",
  maximum_time_per_call: "",
  isTransfer: false,
  action: false,
  knowledge_base_Id: "",
  recording: true,
  is_voice_note: true,
  is_back_sound: "off",
  voice_speed: 1,
  is_filler: false,
  fillers: [],
};
let assistantItem: any = null;
let oldActions: any = null;
let oldZapier: any = null;
const AssistantUpdate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state?.formData;
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [showInput, setShowInput] = useState(false);

  const [isAssitantModalOpen, setIsAssistantModalOpen] = useState(false);
  // const { colorMode } = useColorMode();
  const [voiceList, setVoiceList] = useState([]);
  const [selectedGenderData, setSelectedGenderData] = useState(null);
  const [knowledgeBaseList, setKnowledgeBaseList] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [zapierData, setZapierData] = useState(null);
  const [isOpenBooking, setIsOpenBooking] = useState(false);

  useEffect(() => {
    if (formData) {
      allInitialValues.type = formData?.type ? formData?.type : "outbound";
      allInitialValues.name = formData?.name ? formData?.name : "";
      allInitialValues.language = formData?.language ? formData?.language : "";
      allInitialValues.company_name = formData?.company_name
        ? formData?.company_name
        : "";
      allInitialValues.script = formData?.script
        ? formData?.script?.replace(/\\/g, "")
        : "";
      allInitialValues.goal = formData?.goal ? formData?.goal : "";
      allInitialValues.gender = formData?.gender ? formData?.gender : "";
      allInitialValues.start_speech = formData?.start_speech
        ? formData?.start_speech
        : "Hello @[FirstName](firstName) , ";
      allInitialValues.voice_id = formData?.voice_id ? formData?.voice_id : "";
      allInitialValues.transfer_number = formData?.transfer_number
        ? formData?.transfer_number
        : "";
      allInitialValues.meeting_link = formData?.meeting_link
        ? formData?.meeting_link
        : "";
      allInitialValues.is_voice_note = formData?.is_voice_note;
      allInitialValues.voice_note = formData?.voice_note
        ? formData?.voice_note
        : "";
      allInitialValues.maximum_time_per_call = formData?.maximum_time_per_call
        ? formData?.maximum_time_per_call
        : "";
      allInitialValues.voice_name = formData?.voice_name
        ? formData?.voice_name
        : "";
      allInitialValues.isTransfer = formData?.transfer_number ? true : false;
      allInitialValues.action = formData?.action ? formData?.action : false;
      allInitialValues.knowledge_base_Id = formData?.knowledge_base_id
        ? formData?.knowledge_base_id
        : "";
      allInitialValues.recording = formData?.is_recording;
      allInitialValues.voice_speed = Number(formData?.voice_speed);
      allInitialValues.is_back_sound = formData?.is_back_sound
        ? "office"
        : "off";
      allInitialValues.is_filler = formData?.is_filler;
      const filteredArray = formData?.fillers
        ? formData?.fillers?.split(",")?.map((item: any) => item?.trim())
        : [];
      allInitialValues.fillers = filteredArray;
    }
    formik.setValues(allInitialValues);
    assistantItem = allInitialValues;
    oldActions = JSON.stringify(formData?.actions || null);
    oldZapier = formData?.zapier_hook || null;
    setActionData(formData?.actions || []);
    setZapierData(formData?.zapier_hook || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  let transferCallData =
    actionData && actionData.find((item) => item.type === "transferCall");
  let sendMessageData =
    actionData && actionData.find((item) => item.type === "sendMessage");
  let sendEmailData =
    actionData && actionData.find((item) => item.type === "sendMail");
  let infoExtractorData =
    actionData && actionData.find((item) => item.type === "infoExtractor");
  let questionsData = infoExtractorData?.questions
    ? infoExtractorData?.questions
    : [];
  let bookingSlotData =
    actionData && actionData.find((item) => item.type === "scheduleMeeting");
  const handleRemoveAction = (type: any) => {
    let deletedData = actionData.filter((item) => item.type !== type);
    setActionData(deletedData);
    if (type === "scheduleMeeting") {
      setBookingData({
        timezone: "Europe/Berlin",
        startDate: "",
        description: "",
        say: "",
        length: 30,
      });
    }
  };
  useEffect(() => {
    if (bookingSlotData) {
      setBookingData(bookingSlotData);
    }
  }, [bookingSlotData]);
  const transformActionData = (actions: any) => {
    return actions.map((action: any) => {
      if (action.type === "infoExtractor" && Array.isArray(action.questions)) {
        const transformedQuestions = action.questions.map((question: any) => {
          if (question.type === "openQuestion") {
            const { identifier, lookingFor, outputExamples, ...rest } =
              question;
            return {
              ...rest,
              identifier: identifier,
              question: lookingFor,
              options: outputExamples,
            };
          }
          return question;
        });
        return {
          ...action,
          questions: transformedQuestions,
        };
      }
      return action;
    });
  };
  // const transformOpenQuestionData = (actions: any) => {
  //   const transformedQuestions = actions.map((item: any) => {
  //     if (item.type === "openQuestion") {
  //       const { identifier, lookingFor, outputExamples, ...rest } = item;
  //       return {
  //         ...rest,
  //         identifier: identifier,
  //         lookingFor: lookingFor,
  //         outputExamples: outputExamples,
  //       };
  //     }
  //     return item;
  //   });
  //   return {
  //     questions: transformedQuestions,
  //   };
  // };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Assistant type is required"),
    name: Yup.string().required("Name is required"),
    language: Yup.string().required("Language is required"),
    goal: Yup.string().required("Goal is required"),
    company_name: Yup.string().required("Company name is required"),
    script: Yup.string().required("Script is required"),
    start_speech: Yup.string()
      .required("Call opening message is required")
      .max(255, "Call opening message must be at most 255 characters"),
    is_voice_note: Yup.boolean(),
    voice_note: Yup.string().when(
      ["is_voice_note", "type"],
      ([is_voice_note, type], schema) => {
        if (is_voice_note && type === "outbound") {
          return Yup.string()
            .required(t("assistantForm.voiceNoteValid"))
            .max(255, t("assistantForm.voiceNoteValid2"));
        }
        return schema;
      }
    ),
    maximum_time_per_call: Yup.number().required(
      t("assistantForm.maximumDurationValid")
    ),
    gender: Yup.string().required(t("assistantForm.genderValid")),
    voice_name: Yup.string().required(t("assistantForm.accentValid")),
    isTransfer: Yup.boolean(),
    action: Yup.boolean(),
    is_filler: Yup.boolean(),
    fillers: Yup.array()
      .of(Yup.string())
      .when(["is_filler"], ([is_filler], schema) => {
        if (is_filler) {
          return Yup.array()
            .of(Yup.string())
            .min(1, "Fillers is required")
            .required("Fillers is required");
        }
        return schema;
      }),
  });
  const formik = useFormik({
    initialValues: allInitialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      if (values.transfer_number) {
        let mobileNum = values.transfer_number;
        const isValidNum = isValidPhoneNumber(mobileNum);
        if (!isValidNum) {
          toast({
            description: t("assistantForm.transferNumValidation"),
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          return;
        }
      }
      let myContact = localStorage.getItem("phone");
      if (myContact) {
        if (myContact === values.transfer_number) {
          toast({
            description: t("assistantForm.transferNumNotSamePhone"),
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          return;
        }
      }
      // let act: any = actionData?.length ? actionData : null;
      let act: any = actionData?.length
        ? transformActionData(actionData)
        : null;
      let isSpeech = false;
      if (
        values?.start_speech !== formData?.start_speech ||
        values?.voice_id !== formData?.voice_id
      ) {
        isSpeech = true;
      }
      const payload = {
        type: values?.type,
        name: values?.name,
        language: values?.language,
        goal: values?.goal,
        company_name: values?.company_name,
        script: values?.script?.replace(/\\/g, ""),
        actions: act,
        accent: "US",
        gender: values?.gender,
        voice_name: values?.voice_name,
        start_speech: values?.start_speech,
        voice_id: values?.voice_id,
        transfer_number: values?.isTransfer ? values?.transfer_number : "",
        meeting_link: values?.action ? values.meeting_link : "",
        is_voice_note:
          values.type === "outbound" ? values?.is_voice_note : false,
        voice_note:
          values.type === "outbound"
            ? values?.is_voice_note
              ? values?.voice_note
              : ""
            : "",
        maximum_time_per_call: values?.maximum_time_per_call,
        is_start_speech_update: isSpeech,
        start_speech_wav: formData?.start_speech_wav,
        knowledge_base_Id: values?.knowledge_base_Id
          ? values?.knowledge_base_Id
          : null,
        is_recording: values?.recording,
        zapier_hook: zapierData,
        is_back_sound: values?.is_back_sound === "office" ? true : false,
        voice_speed: values?.voice_speed,
        is_filler: values?.is_filler,
        fillers: values?.is_filler ? values?.fillers.join() : "",
      };
      handleUpdateAssistant(payload);
    },
  });
  // useEffect(() => {
  //   return () => {
  //     formik.resetForm({
  //       values: allInitialValues,
  //     });
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    getCampaignVoiceList()
      .then((response) => {
        if (response.success) {
          const selectedVoice = response?.data.find(
            (item: any) => item?.id === formData?.voice_id
          );
          let voiceD = response?.data.sort((a: any, b: any) => {
            if (a.accent < b.accent) return -1;
            if (a.accent > b.accent) return 1;
            return 0;
          });
          setVoiceList(voiceD);
          setSelectedGenderData(selectedVoice);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    getKnowledgeBaseList()
      .then((response: any) => {
        if (response.success) {
          setKnowledgeBaseList(response.data);
        }
      })
      .catch((error: string) => {
        console.error("Error :", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedGenderData) {
      formik.setFieldValue("gender", selectedGenderData?.gender);
      formik.setFieldValue("voice_name", selectedGenderData?.name);
      formik.setFieldValue("voice_id", selectedGenderData?.id);
      if (selectedGenderData?.ai_modal === "deepgram") {
        formik.setFieldValue("voice_speed", 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGenderData]);

  const [bookingData, setBookingData] = useState({
    timezone: "Europe/Berlin",
    startDate: "",
    description: "",
    say: "",
    length: 30,
  });

  const handleAssistantClose = () => {
    setIsAssistantModalOpen(false);
    setShowInput(false);
  };

  const handleAssistantModal = () => {
    setIsAssistantModalOpen(!isAssitantModalOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleUpdateAssistant = (request: any) => {
    setLoading(true);
    updateAssistant(request, formData?.id)
      .then((response) => {
        if (response.success) {
          assistantItem = formik.values;
          oldActions = JSON.stringify(
            actionData.length > 0 ? actionData : null
          );
          oldZapier = zapierData;
          setTimeout(() => {
            toast({
              description: t("messages.assistantUpdated"),
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            navigate("/admin/assistants");
            setLoading(false);
          }, 500);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error :", error);
        setLoading(false);
      });
  };
  const [currentStep, setCurrentStep] = useState(1);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  let syncData = actionData && actionData.find((item) => item.type === "crm");
  const isChangedActions = actionData.length > 0 ? actionData : null;
  const isChangedzapier = zapierData ? zapierData : null;
  let isDirty =
    JSON.stringify(assistantItem) !== JSON.stringify(formik?.values) ||
    oldActions !== JSON.stringify(isChangedActions) ||
    JSON.stringify(oldZapier) !== JSON.stringify(isChangedzapier);
  usePrompt({
    isDirty,
  });
  return (
    <div
      className="h-full w-full rounded-xl"
      style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 4px" }}
    >
      {loading && <Loader />}

      <div className="flex w-full flex-col items-center justify-between gap-2 border-[#d3d3d3] p-4 sm:border-b-[1px] lg:flex-row">
        <span className="max-w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-[20px] font-bold dark:text-white">
          {formData?.name}
        </span>
        <div className="flex flex-col gap-2 md:flex-row">
          <button
            onClick={() => navigate("/admin/assistants")}
            className="flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
          >
            <FaArrowLeft className="h-4 w-4" />
            {t("goBack")}
          </button>
          <button
            onClick={() => handleAssistantModal()}
            className="linear flex w-fit items-center justify-center gap-2 rounded bg-blue-700 px-3 py-[8px] text-base font-medium text-[#ffffff] transition duration-200 hover:bg-blue-800 active:bg-brand-700 dark:active:bg-brand-200"
          >
            <FaPhoneAlt /> {t("assistantForm.testAssist")}
          </button>
        </div>
      </div>
      <div className={`p-1 pb-[24px] sm:p-4`}>
        <Card extra={"p-6 mb-16 max-w-[900px] m-auto"}>
          <ol className=" flex w-full items-center justify-center sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
            <Tooltip
              label={t("assistantForm.generalInformation")}
              placement="top"
            >
              <li
                className={`flex cursor-pointer items-center space-x-2.5 border-gray-200 pr-6 text-blue-700 dark:text-blue-700 sm:border-r-2 rtl:space-x-reverse`}
              >
                <span
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${
                    currentStep === 1
                      ? "bg-blue-700 text-white dark:text-white"
                      : "bg-blue-100"
                  }`}
                >
                  <IoMdInformationCircle className="h-7 w-7" />
                </span>
                {currentStep === 1 && (
                  <span>
                    <p className="text-sm">{t("assistantForm.step1")}</p>
                    <h3 className="font-semibold leading-tight text-gray-800 dark:text-white">
                      {t("assistantForm.generalInformation")}
                    </h3>
                  </span>
                )}
              </li>
            </Tooltip>
            <Tooltip
              label={t("assistantForm.voiceConfiguration")}
              placement="top"
            >
              <li
                className={`flex cursor-pointer items-center space-x-2.5 border-gray-200 pr-6 text-blue-700 dark:text-blue-700 sm:border-r-2 rtl:space-x-reverse`}
              >
                <span
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${
                    currentStep === 2
                      ? "bg-blue-700 text-white dark:text-white"
                      : "bg-blue-100"
                  }`}
                >
                  <MdSettingsVoice className="h-5 w-5" />
                </span>
                {currentStep === 2 && (
                  <span>
                    <p className="text-sm">{t("assistantForm.step2")}</p>
                    <h3 className="font-medium leading-tight text-gray-800 dark:text-white">
                      {t("assistantForm.voiceConfiguration")}
                    </h3>
                  </span>
                )}
              </li>
            </Tooltip>
            <Tooltip label={t("assistantForm.AIPrompt")} placement="top">
              <li
                className={`flex cursor-pointer items-center space-x-2.5 text-blue-700 dark:text-blue-700 rtl:space-x-reverse`}
              >
                <span
                  className={`flex h-12 w-12 shrink-0 items-center justify-center rounded-full ${
                    currentStep === 3
                      ? "bg-blue-700 text-white dark:text-white"
                      : "bg-blue-100"
                  }`}
                >
                  <AiFillRobot className="h-5 w-5" />
                </span>
                {currentStep === 3 && (
                  <span>
                    <p className="text-sm">{t("assistantForm.step3")}</p>
                    <h3 className="font-semibold leading-tight text-gray-800 dark:text-white">
                      {t("assistantForm.AIPrompt")}
                    </h3>
                  </span>
                )}
              </li>
            </Tooltip>
          </ol>
        </Card>

        {currentStep === 1 && (
          <StepOne formik={formik} handleNextStep={handleNextStep} />
        )}
        {currentStep === 2 && (
          <StepTwo
            formik={formik}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
            voiceList={voiceList}
            selectedGenderData={selectedGenderData}
            setSelectedGenderData={setSelectedGenderData}
          />
        )}
        {currentStep === 3 && (
          <StepThree
            formik={formik}
            handlePrevStep={handlePrevStep}
            knowledgeBaseList={knowledgeBaseList}
            infoExtractorData={infoExtractorData}
            transferCallData={transferCallData}
            sendMessageData={sendMessageData}
            sendEmailData={sendEmailData}
            bookingSlotData={bookingSlotData}
            zapierData={zapierData}
            syncData={syncData}
            handleRemoveAction={handleRemoveAction}
            setZapierData={setZapierData}
            actionData={actionData}
            setActionData={setActionData}
            questionsData={questionsData}
            setBookingData={setBookingData}
            bookingData={bookingData}
            currentDate={""}
            isOpenBooking={isOpenBooking}
            setIsOpenBooking={setIsOpenBooking}
          />
        )}
      </div>
      <AssistantModal
        isOpen={isAssitantModalOpen}
        handleClose={handleAssistantClose}
        setShowInput={setShowInput}
        showInput={showInput}
        assistantSelectedData={formData}
      />
    </div>
  );
};

export default AssistantUpdate;
