/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Card from "components/card";
import { MdDelete, MdEdit, MdPermContactCalendar } from "react-icons/md";
// import ConfirmModal from "components/modal/ConfirmModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import TooltipHorizon from "components/tooltip";
import { getContactList } from "services/crm";
import { useToast } from "@chakra-ui/react";
import AddAudianceModal from "components/modal/AddAudianceModal";
// import EditAudianceModal from "components/modal/EditAudianceModal";
import { GoPlus } from "react-icons/go";
import Loader from "components/Loader/Loader";
import EditAudianceModal from "components/modal/EditAudianceModal";
import ConfirmModal from "components/modal/ConfirmModal";
import { deleteAudience } from "services/contact";
import SearchBarInput from "views/admin/assitstants/assistanceList/components/SearchbarInput";
import { responseHendler } from "utils/utils";
import { useTranslation } from "react-i18next";
// import CompanyEditModal from "components/modal/CompanyEditModal";
// import CompanyAddModal from "components/modal/CompanyAddModal";

type RowObj = {
  fullName: string;
  segment_name: string;
  email: string;
  phone: string;
  job_title: string;
  lifecycle_stage: string;
  lead_status: string;
  sync_by: any;
  actions: any;
};

const Contacts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  // const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [contactList, setContactList] = useState([]);
  // const [tableDataAll, setTableDataAll] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  // const [refresh, setRefresh] = useState(false);

  // const [rowData, setRowData] = useState({});
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAudianceModalOpen, setIsAudianceModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const fetchData = useCallback(() => {
    const params = {
      page: pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchContactList(params);
  }, [pagination?.pageIndex, pagination?.pageSize, searchQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    if (contactList?.length === 1 && pagination?.pageIndex !== 0) {
      setPagination({
        pageIndex: pagination?.pageIndex - 1,
        pageSize: 10,
      });
    }
    const params = {
      page:
        contactList?.length === 1 && pagination?.pageIndex !== 0
          ? pagination?.pageIndex - 1
          : pagination?.pageIndex,
      perpage: pagination?.pageSize,
      search: searchQuery,
    };
    fetchContactList(params);
  }, [refresh]);
  useEffect(() => {
    if (isConfirm === true) {
      let req = {
        id: rowData?.contact_id,
      };
      deleteAudience(req)
        .then((response) => {
          if (response.success) {
            handleConfirmClose();
            setIsConfirm(false);
            setRowData(null);
            toast({
              description: t("messages.contactDeleted"),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            handleConfirmClose();
            setRefresh(!refresh);
          } else {
            toast({
              description: response?.message,
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);
  const fetchContactList = (params: any) => {
    setIsLoading(true);
    getContactList(params)
      .then((response) => {
        setIsLoading(false);
        responseHendler(response);
        if (response.success) {
          if (response?.data) {
            for (let item of response?.data) {
              item.fullName = item.first_name + " " + item.last_name;
            }
            setContactList(response?.data);
            // setTableDataAll(response?.data);
          } else {
            setContactList([]);
            // setTableDataAll([]);
          }
          setTotalPages(response?.totalPages || 0);
        } else {
          toast({
            description: response?.message,
            status: "error",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const handleSearch = (value: any) => {
    // console.log(value);
    // const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    // if (tableDataAll) {
    //   const filteredData = tableDataAll.filter((item: any) => {
    //     return Object.keys(item).some((key) => {
    //       const itemValue = item[key];

    //       if (typeof itemValue === "string") {
    //         return itemValue.toLowerCase().includes(value);
    //       }

    //       if (typeof itemValue === "number") {
    //         return itemValue.toString().includes(value);
    //       }

    //       if (itemValue instanceof Date) {
    //         return itemValue.toLocaleDateString().includes(value);
    //       }

    //       return false;
    //     });
    //   });
    //   setContactList(filteredData);
    // }
  };
  const handleClick = (items: any) => {
    navigate(`/admin/contacts/contact-detail`, {
      state: { rowData: items.row.original },
    });
  };
  const CustomHeader = ({ headerText }: any) => (
    <p className="flex items-center justify-center text-sm font-bold text-gray-600 dark:text-white">
      {headerText}
    </p>
  );
  const columns = [
    columnHelper.accessor("fullName", {
      id: "Name",
      header: () => <CustomHeader headerText={t("contacts.name")} />,
      cell: (info) => (
        <p
          onClick={() => handleClick(info)}
          className="flex cursor-pointer items-center justify-end text-sm font-bold text-navy-700 hover:text-blue-700 hover:underline hover:underline-offset-[4px] dark:text-white sm:justify-center"
        >
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("segment_name", {
      id: "Segment Name",
      header: () => <CustomHeader headerText={t("contacts.segmentName")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("email", {
      id: "Email",
      header: () => <CustomHeader headerText={t("contacts.email")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("phone", {
      id: "Phone",
      header: () => <CustomHeader headerText={t("contacts.phone")} />,
      cell: (info: any) => (
        <TooltipHorizon
          trigger={
            <div className="m-auto max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap sm:justify-center md:text-center">
              {info.getValue()}
            </div>
          }
          placement="top"
          content={info.getValue()}
          extra="rounded text-[11px]"
        />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("job_title", {
      id: "Job Title",
      header: () => <CustomHeader headerText={t("contacts.jobTitle")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("lifecycle_stage", {
      id: "Lifecycle Stage",
      header: () => <CustomHeader headerText={t("contacts.lifecycleStage")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("lead_status", {
      id: "Lead Status",
      header: () => <CustomHeader headerText={t("contacts.leadStatus")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("sync_by", {
      id: "Sync By",
      header: () => <CustomHeader headerText={t("contacts.syncBy")} />,
      cell: (info) => (
        <p className="flex items-center justify-end text-sm text-navy-700 dark:text-white sm:justify-center">
          {info.getValue()}
        </p>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor("actions", {
      id: "Actions",
      header: () => <CustomHeader headerText={t("contacts.actions")} />,
      cell: (info) => (
        <div className="flex  items-center justify-end gap-4 sm:justify-center">
          <MdEdit
            onClick={() => {
              editContact(info?.row?.original);
              if (Array.isArray((window as any).dataLayer)) {
                (window as any).dataLayer.push({
                  event: "Edit Contact",
                  buttonName: "Edit Contact",
                });
              }
            }}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
          <MdDelete
            onClick={() => handleConfirmModal(info.row.original)}
            color="gray"
            className="h-6 w-6 cursor-pointer"
          />
        </div>
      ),
      enableSorting: false,
    }),
  ];
  const table = useReactTable({
    data: contactList,
    columns: columns,
    state: {
      sorting,
      pagination,
    },
    pageCount: totalPages,
    manualPagination: true,
    manualFiltering: true,
    // manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  // const handleClose = () => {
  //   setIsModalOpen(false);
  // };

  // const handleModal = (rowData: RowObj) => {
  //   setRowData(rowData);
  //   setIsModalOpen(true);
  // };

  const handleAudianceClose = () => {
    setIsAudianceModalOpen(false);
  };

  const handleAudianceModal = () => {
    setIsAudianceModalOpen(true);
  };

  const editContact = (item: any) => {
    setRowData(item);
    setIsEditModalOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalOpen(false);
  };

  const handleConfirmModal = (rowData: RowObj) => {
    setRowData(rowData);
    setIsConfirmModalOpen(true);
    setIsConfirm(false);
  };
  return (
    <div>
      {isLoading && <Loader />}
      <div className="mb-2">
        <div className="mt-2 flex justify-end gap-4 py-2">
          <SearchBarInput
            initialData={searchQuery}
            handleFilteredData={handleSearch}
          />
          <button
            onClick={() => handleAudianceModal()}
            className="flex items-center justify-center gap-1  rounded bg-blue-700 px-6 py-0 text-white hover:bg-blue-800"
          >
            <GoPlus className="h-7 w-7" />
            {t("addNew")}
          </button>
        </div>
      </div>

      {contactList?.length === 0 && !isLoading ? (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mb-auto flex flex-col items-center justify-center py-16">
            <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
              <MdPermContactCalendar />
            </div>
            <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
              {t("contacts.noContacts")}
            </h4>
            <p className="px-5 text-center text-base font-normal text-gray-600 md:!px-0 xl:!px-8">
              {t("contacts.getStarted")}
            </p>
            <button
              onClick={() => {
                handleAudianceModal();

                if (Array.isArray((window as any).dataLayer)) {
                  (window as any).dataLayer.push({
                    event: "Add New contact",
                    buttonName: "Add new",
                  });
                }
              }}
              className="mb-10 mt-10 flex items-center justify-center gap-1 rounded bg-blue-700 px-6 py-2 text-white hover:bg-blue-800"
            >
              <GoPlus className="h-7 w-7" />
              {t("addNew")}
            </button>
          </div>
        </Card>
      ) : (
        <Card extra={"w-full sm:overflow-auto px-6"}>
          <div className="mt-1 overflow-x-auto">
            <table className="responsive-table w-full">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          scope="col"
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                        >
                          <div className="flex items-center justify-center gap-1 text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => {
                      const columnHeader = cell.column.id;
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4"
                          data-label={columnHeader}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mb-2 mt-2 flex flex-wrap items-center justify-start gap-2">
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.setPageIndex(0)}
              disabled={pagination?.pageIndex === 0}
            >
              {"<<"}
            </button>
            <button
              className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {"<"}
            </button>
            <div className="flex items-center gap-2">
              <span>
                {t("page")} {table.getState().pagination.pageIndex + 1}{" "}
                {t("of")} {totalPages}
              </span>
              <input
                type="number"
                min="1"
                max={totalPages}
                value={table.getState().pagination.pageIndex + 1}
                onChange={(e) => table.setPageIndex(Number(e.target.value) - 1)}
                className="w-[60px] rounded border border-gray-300 bg-gray-50 pl-2 text-base font-medium text-gray-700"
              />
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                {">"}
              </button>
              <button
                className="linear text-black bg-transparent flex h-fit items-center justify-center gap-2 rounded border-[1px] border-blue-700 px-[16px] py-[6px] text-base font-medium text-blue-700 transition duration-200"
                onClick={() => table.setPageIndex(totalPages - 1)}
                disabled={!table.getCanNextPage()}
              >
                {">>"}
              </button>
            </div>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Card>
      )}

      <AddAudianceModal
        isOpen={isAudianceModalOpen}
        handleClose={handleAudianceClose}
        audienceId={""}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <EditAudianceModal
        isOpen={isEditModalOpen}
        handleClose={handleCloseEdit}
        rowData={rowData}
        setTableData={[]}
        setRefresh={setRefresh}
        refresh={refresh}
        reference="contact"
        contactData={rowData}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        handleClose={handleConfirmClose}
        rowData={rowData}
        setIsConfirm={setIsConfirm}
        type={t("contactText")}
      />
    </div>
  );
};

export default Contacts;
const columnHelper = createColumnHelper<RowObj>();
