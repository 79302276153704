/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import ghl_icon from "../../../../assets/img/ghl_icon.svg";
import make_icon from "../../../../assets/img/make_icon.svg";
import bubble_icon from "../../../../assets/img/bubble_icon.svg";
import bubble_icon_light from "../../../../assets/img/bubble_icon_light.png";
import elevenLabs_icon from "../../../../assets/img/elevenlabs_icon.svg";
import elevenlabs from "../../../../assets/img/elevenlabs.svg";
import smtp_icon from "../../../../assets/img/smtp_icon.svg";
import smtp_icon_light from "../../../../assets/img/smtp-server-icon.svg";
import whatsapp_icon from "../../../../assets/svg/whatsapp.svg";

import { FaCcStripe, FaHubspot, FaSalesforce } from "react-icons/fa";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  connectConnecter,
  connectConnecterCode,
  connectorList,
  connectorRemove,
} from "services/integrations";
import { MdDelete } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { responseHendler } from "utils/utils";
import { useTranslation } from "react-i18next";
const ThirdParty = () => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [connectorListData, setConnectorListData] = useState(null);
  const [connectorTypeSet, setConnectorTypeSet] = useState("");
  const [isOpenRemoveConnectorConfirm, setIsOpenRemoveConnectorConfirm] =
    useState(false);

  const searchParams = new URLSearchParams(location?.search.split("?")[1]);
  const connectorCode = searchParams && searchParams.get("code");
  const userId = localStorage.getItem("user_id");
  const { colorMode } = useColorMode();
  useEffect(() => {
    fetchConnectorList(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  useEffect(() => {
    const connectorType = localStorage.getItem("connectorType");
    if (connectorCode && connectorType && userId) {
      setLoading(true);
      connectConnecterCode(connectorCode, connectorType, userId)
        .then((response) => {
          setLoading(false);
          responseHendler(response);
          if (response.success) {
            toast({
              description: `${
                connectorType === "hubspot"
                  ? t("integrations.integ.hubspot")
                  : connectorType === "salesforce"
                  ? t("integrations.integ.salesforce")
                  : t("integrations.integ.GoHighLevel")
              } ${t("messages.connectedConnector")}`,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
            fetchConnectorList(userId);
            navigate("/admin/integrations");
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorCode]);
  const fetchConnectorList = (userId: string) => {
    connectorList(userId)
      .then((response: any) => {
        setLoading(false);
        if (response?.status === 401) {
          navigate("/auth/sign-in");
        }
        if (response?.success) {
          setConnectorListData(response?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  const handleConnect = (type: any) => {
    if (type === "hubspot" && !connectorListData?.hubspot) {
      localStorage.setItem("connectorType", type);
      setLoading(true);
      connectConnecter(type)
        .then((response) => {
          setLoading(false);
          responseHendler(response);
          if (response.success) {
            window.location.href = response?.data;
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    }
    if (type === "salesforce" && !connectorListData?.salesForce) {
      localStorage.setItem("connectorType", type);
      setLoading(true);
      connectConnecter(type)
        .then((response) => {
          setLoading(false);
          responseHendler(response);
          if (response.success) {
            window.location.href = response?.data;
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    }
    if (type === "gohighlevel" && !connectorListData?.gohighlevel) {
      localStorage.setItem("connectorType", type);
      setLoading(true);
      connectConnecter(type)
        .then((response) => {
          setLoading(false);
          responseHendler(response);
          if (response.success) {
            window.location.href = response?.data;
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    }
  };
  const handleRemoveConnector = (status: string) => {
    setConnectorTypeSet(status);
    setIsOpenRemoveConnectorConfirm(true);
  };
  const handleCloseConnectorConfirm = () => {
    setIsOpenRemoveConnectorConfirm(false);
  };
  const removeConnector = () => {
    setLoading(true);
    connectorRemove(connectorTypeSet, userId)
      .then((response) => {
        setLoading(false);
        responseHendler(response);
        if (response.success) {
          toast({
            description: `${
              connectorTypeSet === "hubspot"
                ? t("integrations.integ.hubspot")
                : connectorTypeSet === "salesforce"
                ? t("integrations.integ.salesforce")
                : t("integrations.integ.GoHighLevel")
            } ${t("messages.removeConnector")}`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
          fetchConnectorList(userId);
        } else {
          toast({
            description: response?.message,
            status: "info",
            duration: 5000,
            isClosable: true,
            position: "top-left",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  return (
    <div>
      {loading && <Loader />}
      <span className="mb-[10px] mt-5 text-[12px] font-semibold text-gray-600">
        {t("integrations.allIntegrations")}
      </span>
      <div className="mt-2 grid grid-cols-1 gap-[10px] sm:grid-cols-2 md:grid-cols-3">
        <div
          className="flex cursor-pointer flex-col gap-[10px] rounded border bg-white p-[15px] shadow dark:!bg-navy-700 dark:text-white"
          onClick={() => handleConnect("hubspot")}
        >
          <div className="flex w-full items-center justify-between">
            {/* <img
              src={smtp_icon}
              alt="smtp logo"
              className="h-[32px] w-[32px]"
            /> */}
            <FaHubspot className="h-[32px] w-[32px]" />
            <div className="flex items-center gap-4">
              {connectorListData?.hubspot && (
                <span>
                  <MdDelete
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveConnector("hubspot");
                    }}
                    className={`h-6 w-6 cursor-pointer`}
                    color="red"
                  />
                </span>
              )}
              {connectorListData?.hubspot && (
                <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-green-600">
                  {t("integrations.integ.connected")}
                </span>
              )}
            </div>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.hubspot")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.connectWithHubspot")}
          </div>
        </div>
        <div
          className="flex cursor-pointer flex-col gap-[10px] rounded border bg-white p-[15px] shadow  dark:!bg-navy-700 dark:text-white"
          onClick={() => handleConnect("salesforce")}
        >
          <div className="flex w-full items-center justify-between">
            {/* <img
              src={stripe_icon}
              alt="stripe logo"
              className="h-[32px] w-[32px]"
            /> */}
            <FaSalesforce className="h-[32px] w-[32px]" />
            <div className="flex items-center gap-4">
              {connectorListData?.salesForce && (
                <span>
                  <MdDelete
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveConnector("salesforce");
                    }}
                    className={`h-6 w-6 cursor-pointer`}
                    color="red"
                  />
                </span>
              )}
              {connectorListData?.salesForce && (
                <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-green-600">
                  {t("integrations.integ.connected")}
                </span>
              )}
            </div>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.salesforce")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.connectWithSalesforce")}
          </div>
        </div>
        <div
          className="flex cursor-pointer flex-col gap-[10px] rounded border bg-white p-[15px] shadow  dark:!bg-navy-700 dark:text-white"
          onClick={() => navigate("/admin/smtp-integration")}
        >
          <div className="flex w-full items-center justify-between">
            <img
              src={colorMode === "light" ? smtp_icon : smtp_icon_light}
              alt="smtp logo"
              className="h-[32px] w-[32px]"
            />
            {/* <span className="rounded-md bg-blue-700 p-1 text-[12px] font-semibold text-white"> */}
            <IoMdSettings className={`h-6 w-6 cursor-pointer text-blue-700`} />
            {/* </span> */}
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.SMTP")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.SMTPCaption")}
          </div>
        </div>
        <div
          className="flex cursor-pointer flex-col gap-[10px] rounded border bg-white p-[15px] shadow  dark:!bg-navy-700 dark:text-white"
          onClick={() => handleConnect("gohighlevel")}
        >
          <div className="flex w-full items-center justify-between">
            <img
              src={ghl_icon}
              alt="Go high level logo"
              className="h-[32px] w-[32px]"
            />
            {/* <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-blue-600">
              {t("integrations.integ.comingSoon")}
            </span> */}
            <div className="flex items-center gap-4">
              {connectorListData?.goHighLevel && (
                <span>
                  <MdDelete
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveConnector("gohighlevel");
                    }}
                    className={`h-6 w-6 cursor-pointer`}
                    color="red"
                  />
                </span>
              )}
              {connectorListData?.goHighLevel && (
                <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-green-600">
                  {t("integrations.integ.connected")}
                </span>
              )}
            </div>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.GoHighLevel")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.GoHighLevelCaption")}
          </div>
        </div>
        <div
          className="flex cursor-pointer flex-col gap-[10px] rounded border bg-white p-[15px] shadow  dark:!bg-navy-700 dark:text-white"
          onClick={() => navigate("/admin/whatsapp-integration")}
        >
          <div className="flex w-full items-center justify-between">
            <img
              src={whatsapp_icon}
              alt="whatsapp logo"
              className="h-[32px] w-[32px]"
            />
            {/* <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-blue-600">
              {t("integrations.integ.comingSoon")}
            </span> */}
            <IoMdSettings className={`h-6 w-6 cursor-pointer text-blue-700`} />
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.WhatsApp")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.connectWithWhatsApp")}
          </div>
        </div>
        <div className="flex flex-col gap-[10px] rounded border bg-white p-[15px] shadow  dark:!bg-navy-700 dark:text-white">
          <div className="flex w-full items-center justify-between">
            <FaCcStripe className="h-[32px] w-[32px]" />
            <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-blue-600">
              {t("integrations.integ.comingSoon")}
            </span>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.Stripe")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.StripeCaption")}
          </div>
        </div>
        <div className="flex flex-col gap-[10px] rounded border bg-white p-[15px] shadow dark:!bg-navy-700 dark:text-white">
          <div className="flex w-full items-center justify-between">
            <img
              src={make_icon}
              alt="make logo"
              className="h-[32px] w-[32px]"
            />
            <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-blue-600">
              {t("integrations.integ.comingSoon")}
            </span>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.Make")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.MakeCaption")}
          </div>
        </div>
        <div className="flex flex-col gap-[10px] rounded border bg-white p-[15px] shadow dark:!bg-navy-700 dark:text-white">
          <div className="flex w-full items-center justify-between">
            <img
              src={colorMode === "light" ? elevenLabs_icon : elevenlabs}
              alt="elevenLabs logo"
              className="h-[32px] w-[32px]"
            />
            <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-blue-600">
              {t("integrations.integ.comingSoon")}
            </span>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.ElevenLabs")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.ElevenLabsCaption")}
          </div>
        </div>
        <div className="flex flex-col gap-[10px] rounded border bg-white p-[15px] shadow dark:!bg-navy-700 dark:text-white">
          <div className="flex w-full items-center justify-between">
            <img
              src={colorMode === "light" ? bubble_icon : bubble_icon_light}
              alt="bubble logo"
              className="h-[32px] w-[32px]"
            />
            <span className="rounded-md bg-blue-100 p-1 text-[12px] font-semibold text-blue-600">
              {t("integrations.integ.comingSoon")}
            </span>
          </div>
          <span className="text-[16px] font-medium text-[#000000] dark:text-white">
            {t("integrations.integ.bubble")}
          </span>
          <div className="text-[14px] text-gray-600">
            {t("integrations.integ.bubbleCaption")}
          </div>
        </div>
      </div>
      <Modal
        isCentered
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpenRemoveConnectorConfirm}
        onClose={handleCloseConnectorConfirm}
        scrollBehavior={"inside"}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent className="dark:bg-navy-900">
          <ModalCloseButton />
          <ModalBody pb={6}>
            <div className="mt-6 flex flex-col items-center justify-center">
              <svg
                className="mx-auto mb-3.5 h-11 w-11 text-red-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-[#000000] dark:text-white">
                {t("integrations.integ.removeConnectorsText")}{" "}
                {connectorTypeSet}?
              </p>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                removeConnector();
                handleCloseConnectorConfirm();
              }}
              colorScheme="blue"
              mr={3}
              className="dark:bg-blue-700 dark:text-white"
            >
              {t("yes")}
            </Button>
            <Button onClick={handleCloseConnectorConfirm}>{t("cancel")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ThirdParty;
