import { HiX } from "react-icons/hi";
// import Links from "./components/Links";
import useRoutes from "routes";
import { Link } from "react-router-dom";
import logoImg from "../../assets/img/precallai-logo.png";
// import FreeCard from "./components/SidebarCard";
import lightlogo from "../../assets/img/precallai-log-white.png";
import lightlogoSmall from "../../assets/img/precallAi.png";
import { useColorMode } from "@chakra-ui/system";
import { useEffect } from "react";
import { profileDetail } from "services/Auth";
import RouterLinks from "./routerLinks";
// import { IoMdArrowDropleft } from "react-icons/io";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
const Sidebar = (props: {
  isOpen: boolean;
  setIsOpen: any;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: any;
  isLargerThanMD: any;
  handleSidebarToggle: any;
}) => {
  const {
    isOpen,
    setIsOpen,
    isMobileMenuOpen,
    isLargerThanMD,
    handleSidebarToggle,
  } = props;
  const { colorMode } = useColorMode();
  const routes = useRoutes();
  // const [amount, setAmount] = useState(localStorage.getItem("amount") || "");
  // const [isOpen, setIsOpen] = useState(true);
  // const [storageChangeKey, setStorageChangeKey] = useState(0);

  // useEffect(() => {
  //   const handleStorageChange = (event: any) => {
  //     if (event.key === 'amount') {
  //       setAmount(event.newValue);
  //       // Update storageChangeKey to trigger rerender
  //       setStorageChangeKey((prevKey) => prevKey + 1);
  //     }
  //   };

  //   // Add event listener for storage changes
  //   window.addEventListener('storage', handleStorageChange);

  //   // Cleanup function to remove event listener
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [storageChangeKey]);

  useEffect(() => {
    profileDetail()
      .then((response) => {
        if (response?.success) {
          // setAmount(response?.data[0]?.amount);
          localStorage?.setItem("amount", response?.data[0]?.amount);
          localStorage?.setItem("phone", response?.data[0]?.phone);
          localStorage?.setItem("profileImage", response?.data[0]?.profile_pic);
          localStorage?.setItem(
            "affiliation_amount",
            response?.data[0]?.affiliation_amount
          );
          localStorage?.setItem("user_id", response?.data[0]?.id);
          localStorage?.setItem(
            "affiliate_percentage",
            response?.affiliate_per
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  }, []);
  return (
    <div
      className={`fixed z-[100] h-screen overflow-hidden overflow-y-auto bg-white duration-300 dark:!bg-navy-800 dark:text-white ${
        isLargerThanMD
          ? isOpen
            ? "w-[270px] p-5"
            : "w-20 p-4"
          : isMobileMenuOpen
          ? "w-64 p-5"
          : "w-0 p-0"
      }`}
    >
      <div>
        <div className="relative">
          <div className="absolute right-4 top-0 block cursor-pointer md:hidden">
            <HiX
              className={`z-50 cursor-pointer rounded-full bg-blue-700 text-2xl text-white`}
              onClick={handleSidebarToggle}
            />
          </div>
          <div className="flex items-center justify-start">
            {isOpen ? (
              <Link to={"/admin/dashboard"}>
                {colorMode === "dark" ? (
                  <img
                    src={lightlogo}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      maxWidth: "150px",
                    }}
                    className={`duration-500`}
                  />
                ) : (
                  <img
                    src={logoImg}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      maxWidth: "150px",
                    }}
                    className={`duration-500`}
                  />
                )}
              </Link>
            ) : (
              <Link to={"/admin/dashboard"}>
                <img
                  src={lightlogoSmall}
                  alt="logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  className={`duration-500 ${!isOpen && "mt-8"}`}
                />
              </Link>
            )}
          </div>
          {isLargerThanMD && (
            <div
              className={`flex items-center ${
                isOpen ? "justify-end" : "justify-center"
              }`}
              style={{
                position: "absolute",
                top: -8,
                right: isOpen ? -20 : 10,
              }}
            >
              <TbLayoutSidebarLeftCollapse
                className={`z-50 cursor-pointer text-2xl text-gray-700 dark:text-white ${
                  !isOpen && "rotate-180"
                }`}
                onClick={() => {
                  setTimeout(() => {
                    setIsOpen(!isOpen);
                  }, 100);
                }}
              />
            </div>
          )}
        </div>
        <div className="mb-7 mt-[30px] h-px bg-gray-300 dark:bg-white/30" />

        <ul className="mb-auto pt-1">
          <RouterLinks routes={routes} isOpen={isOpen} />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
