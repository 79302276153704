//CREATE PLAYGROUND
import { errorHandler } from "utils/errorHandler";
import axios, { AxiosRequestConfig } from "axios";
export const playGroundCall = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    // const payloadData = {
    //   phone_number: data.phone_number,
    //   language: data.language,
    //   start_speech: data.start_speech,
    //   script: data.script,
    //   transfer_number: data.transfer_number,
    //   maximum_time_per_call: data.maximum_time_per_call,
    //   voice_id: data?.voice_id,
    //   voice_name: data?.voice_name,
    // };

    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${baseUrl}/playground/call`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// GET PLAYGROUND

export const getPlayground = async (params: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/playground/list?${queryString}`,
      config
    );
    return response.data;
  } catch (error: any) {
    errorHandler(error);
  }
};

//CREATE PLAYGROUNDFEEDBACK

export const playGroundFeedback = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${baseUrl}/playground/feedback`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// PLAYGROUND Favourite

export const playGroundFavourite = async (data: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${baseUrl}/playground/doFav`,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};
