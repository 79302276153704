/* eslint-disable react-hooks/exhaustive-deps */
import authImg from "assets/img/auth/auth.png";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import useRoutes from "routes";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { useEffect } from "react";
// import { useEffect, useState } from "react";
// import Footer from "components/footer/FooterAuthDefault";

export default function Auth() {
  const location = useLocation();
  const routes = useRoutes();
  // const [isOnBoarding, setIsOnBoarding] = useState(false);
  useEffect(() => {
    setDocTitle(routes);
  }, [location.pathname]);
  useEffect(() => {
    let isThemeChange = localStorage.getItem("isThemeChange");
    let themeMode = localStorage.getItem("themeMode");
    if (isThemeChange) {
      if (themeMode === "dark") {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    }
  }, []);
  const setDocTitle = (routes: RoutesType[]): string | boolean => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].layout) !== -1) {
        document.title = `${routes[i].name} | PreCallAI`;
      }
    }
    return;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/sign-up") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/forgot-password") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/reset-password") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/sign-in") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/onboarding") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/verifyuser") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/auth/verify") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  // useEffect(() => {
  //   if (
  //     location.pathname === "/auth/onboarding" ||
  //     location.pathname === "/auth/verifyuser" ||
  //     location.pathname === "/auth/verify"
  //   ) {
  //     setIsOnBoarding(true);
  //   } else {
  //     setIsOnBoarding(false);
  //   }
  // }, [location]);
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div
              className={`flex min-h-full w-full flex-col justify-start pt-12 md:w-[100%] lg:h-screen lg:w-[58%] lg:pt-0 xl:h-[100vh]  xl:px-0`}
            >
              <div className="mb-auto flex h-full flex-col overflow-auto pl-5 pr-5 md:pl-12 md:pr-12 lg:px-8 xl:max-w-full">
                <div className="mx-auto">
                  <Routes>
                    {getRoutes(routes)}
                    <Route
                      path="/"
                      element={<Navigate to="/auth/sign-in" replace />}
                    />
                  </Routes>
                </div>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[42%] 2xl:w-[42%]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
